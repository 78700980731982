<div v-if="isActiveModal" class="produce_rsa_history">
    <div class="overlay"></div>
    <div class="modal">
        <h2>RSA制作履歴</h2>
        <div class="cr_table">
<!--            <div class="cr_table_action">-->
<!--            </div>-->

            <div class="table_header">
                <table>
                    <tr>
                        <th class="created_at">登録日時</th>
                        <th class="created_user">登録者</th>
                        <th class="campaign_name">キャンペーン名</th>
                        <th class="adgroup_name">広告グループ名</th>
                        <th class="score">スコア</th>
                        <th class="edit"></th>
                    </tr>
                </table>
            </div>

            <div class="table_scroll">
                <table>
                    <tr v-for="rsaProduct in rsaProducts">
                        <td class="created_at">{{ rsaProduct.created_at }}</td>
                        <td class="created_user">{{ rsaProduct.created_user }}</td>
                        <td class="campaign_name">{{ rsaProduct.campaign_name }}</td>
                        <td class="adgroup_name">{{ rsaProduct.adgroup_name }}</td>
                        <td class="score">{{ scoreFormat(rsaProduct.rsa_product_score) }}</td>
                        <td class="edit"><button class="btn" @click="onClickEditRSAProduct(rsaProduct)">再編集</button></td>
                    </tr>
                </table>
                <div class="no_content" v-show="rsaProducts.length <= 0">
                    <span>RSA制作履歴はありません</span>
                </div>
            </div>
        </div>


        <div class="modal_action">
            <button class="btn cancel" @click="closeProduceRSAHistoryModal">Cancel</button>
        </div>
    </div>
</div>
