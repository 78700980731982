import {
  ASSET_TYPE,
  PowerWordAsset,
  NGWord,
  BulkEstimateRSAAdAsset,
  BulkDownloadRSAAd,
  BulkDownloadPreCombinedAsset,
} from '@/stores/model/domain';
import {Format, numberFormat} from '@/utils';

export class CSVDownloadContents {
  private separateChar = '\t'; // csvという表記だが実際はtsv
  private newLineChar = '\r\n';

  public generatePowerWordAssetContent(pas: PowerWordAsset[]): string {
    const headers = [
      'ID',
      'Type',
      'Asset',
      '登録者',
      '登録日'].join(this.separateChar) + this.newLineChar;
    let body: string = '';
    pas.forEach((pa) => {
      const line = [
        pa.pw_asset_id,
        Format.assetType(pa.pw_asset_type),
        pa.word,
        pa.created_user,
        pa.created_at,
      ].map(this.escape).join(this.separateChar);
      body += line + this.newLineChar;
    });
    return headers + body;
  }

  public generateUploadKeywordFormat(): string {
    const headers = [
      'MediaId（Yahoo=1 Google=2）（必須）',
      'AccountId（必須）',
      'CampaignId（必須）',
      'AdGroupId（必須）',
      'KeywordId（必須）',
      'Keyword（任意）',
      'MatchType（任意）',
    ].join(this.separateChar) + this.newLineChar;
    const templateContent = [
      '',
      '',
      '',
      '',
      '',
      '',
      ''].join(this.separateChar) + this.newLineChar;
    return headers + templateContent;
  }

  public generateNGWordFormat(): string {
    const headers = [
      'ID',
      'AITD ID',
      '審査AI ID',
      'NGWord',
      '代替ワード',
      'NG理由',
      'メディアID',
      'アカウントID',
      'キャンペーンID',
      '広告グループID',
      '作成者',
      '作成日',
      '更新者',
      '更新日',
    ].join(this.separateChar) + this.newLineChar;
    const templateContent = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''].join(this.separateChar) + this.newLineChar;
    return headers + templateContent;
  }

  public generateNGWordContent(ngws: NGWord[]): string {
    const headers = [
      'ID',
      'AITD ID',
      '審査AI ID',
      'NGWord',
      '代替ワード',
      'NG理由',
      'メディアID',
      'アカウントID',
      'キャンペーンID',
      '広告グループID',
      '作成者',
      '作成日',
      '更新者',
      '更新日',
    ].join(this.separateChar) + this.newLineChar;
    let body: string = '';
    ngws.forEach((ngw) => {
      const line = [
        ngw.ng_word_id,
        ngw.aitd_upload_id || '',
        ngw.inspector_rule_id || '',
        ngw.ng_word,
        ngw.replaced_word || '',
        ngw.reason || '',
        ngw.media_id || '',
        ngw.account_id || '',
        ngw.campaign_id || '',
        ngw.adgroup_id || '',
        ngw.created_user || '',
        ngw.created_at || '',
        ngw.updated_user || '',
        ngw.updated_at || '',
      ].map(this.escape).join(this.separateChar);
      body += line + this.newLineChar;
    });
    return headers + body;
  }

  public generateBulkEstimateRSAAdAssetFormat(): string {
    const headers = [
      'MediaId（Yahoo=1 Google=2）（必須）',
      'AccountId（必須）',
      'CampaignId（必須）',
      'AdGroupId（必須）',
      'アドナンバー',
      'AssetType（TITLE/DESCRIPTION）',
      'PinnedField',
      'Asset',
      'IsEditable（false=0 true=1）',
    ].join(this.separateChar) + this.newLineChar;
    const templateContent = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ].join(this.separateChar) + this.newLineChar;
    return headers + templateContent;
  }

  public generateBulkEstimateRSAAdAssetContent(beras: BulkEstimateRSAAdAsset[]): string {
    const headers = [
      'MediaId（Yahoo=1 Google=2）（必須）',
      'AccountId（必須）',
      'CampaignId（必須）',
      'AdGroupId（必須）',
      'アドナンバー',
      'AssetType（TITLE/DESCRIPTION）',
      'PinnedField',
      'Asset',
      'IsEditable（false=0 true=1)',
      '有効性',
      'スコア',
    ].join(this.separateChar) + this.newLineChar;
    let body: string = '';
    beras.forEach((bera) => {
      let scoreStr = '-'
      if (!!bera.score) {
        scoreStr = numberFormat(bera.score, 2, 2)
      }
      // @ts-ignore
      const assetTypeKey = ASSET_TYPE[bera.asset_type]
      const line = [
        bera.media_id,
        bera.account_id,
        bera.campaign_id,
        bera.adgroup_id,
        bera.ad_number,
        assetTypeKey,
        Format.pinnedFieldNum(bera.pinned_field),
        bera.word,
        bera.is_editable ? '1' : '0',
        Format.adStrengthAsJPN(bera.ad_strength),
        scoreStr,
      ].map(this.escape).join(this.separateChar);
      body += line + this.newLineChar;
    });
    return headers + body;
  }

  public generateBulkRSAAdContent(bras: BulkDownloadRSAAd[]): string {
    const headers = [
      'MediaId（Yahoo=1 Google=2）',
      'AccountId',
      'CampaignId',
      'AdGroupId',
      'AdId',
      'AssetType（TITLE/DESCRIPTION）',
      'PinnedField',
      'Asset',
      '有効性',
      'スコア',
    ].join(this.separateChar) + this.newLineChar;
    let body: string = '';
    bras.forEach((bra) => {
      let scoreStr = '-'
      if (!!bra.score) {
        scoreStr = numberFormat(bra.score, 2, 2)
      }
      // @ts-ignore
      const assetTypeKey = bra.asset_type != null ? ASSET_TYPE[bra.asset_type] : '-'
      const line = [
        bra.media_id,
        bra.account_id,
        bra.campaign_id,
        bra.adgroup_id,
        bra.ad_id ? bra.ad_id : '-',
        assetTypeKey,
        Format.pinnedFieldNum(bra.pinned_field),
        bra.word ? bra.word : '-',
        Format.adStrengthAsJPN(bra.ad_strength),
        scoreStr,
      ].map(this.escape).join(this.separateChar);
      body += line + this.newLineChar;
    });
    return headers + body;
  }

  public generateBulkPreCombinedAssetContent(bpcas: BulkDownloadPreCombinedAsset[]): string {
    const headers = [
      'MediaId（Yahoo=1 Google=2）',
      'AccountId',
      'CampaignId',
      'AdGroupId',
      'PreCombinedRSAId：SelectionPriority',
      'AssetType（TITLE/DESCRIPTION）',
      '新規/既存',
      'Asset',
      'PinnedField',
      '有効性',
      'スコア',
      'AdId',
      'AssetType（TITLE/DESCRIPTION）',
      'Imp',
      'Asset',
      'PinnedField',
      'スコア',
    ].join(this.separateChar) + this.newLineChar;
    let body: string = '';
    bpcas.forEach((bpca) => {
      // champion asset
      let championScoreStr = ''
      if (!!bpca.champion_score) {
        championScoreStr = numberFormat(bpca.champion_score, 2, 2)
      }
      // @ts-ignore
      const championAssetTypeKey = bpca.champion_asset_type != null ? ASSET_TYPE[bpca.champion_asset_type] : ''

      // candidate asset
      let candidateScoreStr = '-'
      if (!!bpca.candidate_score) {
        candidateScoreStr = numberFormat(bpca.candidate_score, 2, 2)
      }
      let isNewStr = '既存'
      if (bpca.is_new) {
        isNewStr = '新規'
      }
      // @ts-ignore
      const candidateAssetTypeKey = ASSET_TYPE[bpca.candidate_asset_type]
      const line = [
        bpca.media_id,
        bpca.account_id,
        bpca.campaign_id,
        bpca.adgroup_id,
        bpca.pre_combined_rsa_id  + ':' + bpca.selection_priority,
        candidateAssetTypeKey,
        isNewStr,
        bpca.candidate_word,
        Format.pinnedFieldNum(bpca.candidate_pinned_field),
        Format.adStrengthAsJPN(bpca.candidate_ad_strength),
        candidateScoreStr,
        bpca.champion_ad_id ? bpca.champion_ad_id : '',
        championAssetTypeKey,
        // bpca.champion_xxxはもともとOptionalなので、champion_wordで判定
        bpca.champion_word ? bpca.champion_imp : '',
        bpca.champion_word ? bpca.champion_word : '',
        bpca.champion_word ? Format.pinnedFieldNum(bpca.champion_pinned_field) : '',
        championScoreStr,
      ].map(this.escape).join(this.separateChar)
      body += line + this.newLineChar
    });
    return headers + body;
  }

  private escape(column: string | number | null): string {
    return '' + column;
  }
}
