<div>
    <h2><i class="fas fa-caret-right"></i>Asset登録</h2>
    <div v-show="!!selectedKeyword">
        <div class="create_content">
            <div class="create_content_right">
                <div class="input_create">
                    <div class="type_select">
                        <input type="radio" name="pw_asset_kw_type" id="asset_kw_type_select_by_client" v-model="inputPWAssetKeywordType" value="ByClient" checked="checked">
                        <label for="asset_kw_type_select_by_client"><i class="fas fa-circle"></i>クライアント共通</label>
                        <input type="radio" name="pw_asset_kw_type" id="asset_kw_type_select_by_keyword" v-model="inputPWAssetKeywordType" value="ByKeyword">
                        <label for="asset_kw_type_select_by_keyword"><i class="fas fa-circle"></i>キーワードごと</label>
                    </div>

                    <div class="input_asset">
                        <input type="radio" name="asset_type_select" id="asset_type_select_title" v-model="inputAssetType" value="Title" checked="checked">
                        <label for="asset_type_select_title" class=""><i class="fas fa-circle"></i>Title登録</label>
                        <input type="text" id="asset_title_count" placeholder="Title" class="title" @focus="onFocus('Title')" v-model="inputTitle">
                        <p v-bind:class="{error: textFullLength(inputTitle)>30}">入力文字数：<span class="title_count">{{ textFullLength(inputTitle) }}</span> / 30 文字</p>

                        <input type="radio" name="asset_type_select" id="asset_type_select_description" v-model="inputAssetType" value="Description">
                        <label for="asset_type_select_description" class=""><i class="fas fa-circle"></i>Description登録</label>
                        <textarea id="asset_description_count" rows="1" maxlength="90" placeholder="Description" @focus="onFocus('Description')" v-model="inputDescription"></textarea>
                        <p v-bind:class="{error: textFullLength(inputDescription)>90}">入力文字数：<span class="desc_count">{{ textFullLength(inputDescription) }}</span> / 90 文字</p>
                    </div>
                </div>
                <div class="estimate_block">
                    <div v-show="inputPWAssetKeywordType === 'ByKeyword'" class="btn upload" @click="estimateAsset">予測（オプション）</div>
                    <div v-show="inputPWAssetKeywordType === 'ByKeyword'" class="estimate_score">{{ estimateScore ? scoreFormat(estimateScore) : '-' }}</div>
                </div>
                <div class="btn run" @click="postAsset">登録する</div>
                
            </div>
        </div>

        <div class="cr_table">
            <div class="cr_table_action">
                <div class="asset_select">
                    <input type="radio" name="asset_registration_search_pw_asset_type" id="asset_registration_search_pw_asset_type_all" @click="changeSearchPWAssetType('All')" :checked="isSearchPWAssetTypeAll">
                    <label for="asset_registration_search_pw_asset_type_all">全て</label>

                    <input type="radio" name="asset_registration_search_pw_asset_type" id="asset_registration_search_pw_asset_type_title" @click="changeSearchPWAssetType('Title')" :checked="isSearchPWAssetTypeTitle">
                    <label for="asset_registration_search_pw_asset_type_title">Title</label>

                    <input type="radio" name="asset_registration_search_pw_asset_type" id="asset_registration_search_pw_asset_type_description" @click="changeSearchPWAssetType('Description')" :checked="isSearchPWAssetTypeDescription">
                    <label for="asset_registration_search_pw_asset_type_description">Description</label>
                </div>

                <div class="cr_table_search text_search">
                    <input type="text" placeholder="検索" @input="changeSearchPWAssetWord" :value="searchPWAssetWord">
                    <i class="fas fa-search"></i>
                </div>
                <div class="btn download" @click="downloadPowerWordAssets">Download</div>
            </div>

            <div class="table_header">
                <table>
                    <tbody><tr>
                        <th class="id">ID</th>
                        <th class="type">アセット種別</th>
                        <th class="type">キーワード種別</th>
                        <th class="asset_kw">キーワード</th>
                        <th class="asset_asset">Asset</th>
                        <th class="score">登録時<br>スコア</th>
                        <th class="update_name">登録者</th>
                        <th class="date">登録日</th>
                        <th class="delete">削除</th>
                    </tr>
                    </tbody></table>
            </div>

            <div class="table_scroll">
                <table>
                    <tbody>
                        <tr v-for="pwa in pwAssetsForView">
                            <td class="id">{{ pwa.pw_asset_id }}</td>
                            <td class="type">{{ assetTypeFormat(pwa.pw_asset_type) }}</td>
                            <td class="type">{{ keywordTypeFormat(pwa.pw_asset_kw_type) }}</td>
                            <td class="asset_kw">{{ pwa.keyword ? pwa.keyword : '-' }}</td>
                            <td class="asset_asset">{{ pwa.word }}</td>
                            <td class="score">{{ scoreFormat(pwa.asset_score) }}</td>
                            <td class="update_name">{{ pwa.created_user }}</td>
                            <td class="date">{{ pwa.created_at }}</td>
                            <td class="delete"><i class="fas fa-times-circle" @click="deleteAsset(pwa)"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="no_content" v-show="pwAssetsForView.length <= 0">
                    <span>PWアセットはありません</span>
                </div>
            </div>
        </div>
    </div>
</div>
