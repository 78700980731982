<div>
    <h2><i class="fas fa-caret-right"></i>NGワード</h2>
    <div v-show="!!selectedClient" class="ng_word">
        <div class="file_upload">
            <input class="upload_file" ref="inputFile" type="file" @change="setFile($event)">
            <div class="upload_area"
                 @click="clickDragdropArea"
                 v-bind:class="{'drag_over': isFileValid, 'drag_error': isFileError}"
                 @dragover.prevent
                 @dragleave.prevent
                 @drop.prevent="setFile($event)">ドラック＆ドロップまたはクリック</div>
            <div v-show="isFileValid">
                <p>{{ fileName }}</p>
                <p>マッチする行数は{{ uploadNGWords.length }}件です</p>
            </div>
            <p class="error" v-show="isFileError" v-html="errorMessage"></p>
        </div>

        <div class="modal_action">
            <button class="btn upload" @click="downloadFormat">Download Format</button>
            <button class="btn run file_upload" v-bind:disabled="!isFileValid" @click="execUploadNGWords">Upload</button>
        </div>

        <div class="cr_table ng_word_table">
            <div class="cr_table_action">
                <button class="btn download" @click="onClickDownload">Download</button>
            </div>

            <div class="table_header">
                <table>
                    <tr>
                        <th class="ng_word_id">ID</th>
                        <th class="ng_word">NGワード</th>
                        <th class="replaced_word">代替ワード</th>
                        <th>理由</th>
                        <th class="bl_account_id">アカウントID(メディアID)</th>
                        <th class="bl_campaign_id">キャンペーンID</th>
                        <th class="bl_group_id">広告グループID</th>
                        <th class="created_by">作成者</th>
                        <th class="created_date">作成日</th>
                        <th class="modified_by">更新者</th>
                        <th class="modified_date">更新日</th>
                        <th class="delete"></th>
                    </tr>
                </table>
            </div>

            <div class="table_scroll">
                <table>
                    <tr v-for="ngWord in ngWords">
                        <td class="ng_word_id">{{ ngWord.aitd_upload_id || ngWord.inspector_rule_id || '' }}</td>
                        <td class="ng_word">{{ ngWord.ng_word }}</td>
                        <td class="replaced_word">{{ ngWord.replaced_word || '-' }}</td>
                        <td>{{ ngWord.reason || '-' }}</td>
                        <td class="bl_account_id">
                            <span v-if="ngWord.account_id && ngWord.media_id">{{ ngWord.account_id }}({{ ngWord.media_id }})</span>
                            <span v-else>-</span>
                        </td>
                        <td class="bl_campaign_id">{{ ngWord.campaign_id || '-' }}</td>
                        <td class="bl_group_id">{{ ngWord.adgroup_id || '-' }}</td>
                        <td class="created_by">{{ ngWord.created_user || '-' }}</td>
                        <td class="created_date">{{ ngWord.created_at || '-' }}</td>
                        <td class="modified_by">{{ ngWord.updated_user || '-' }}</td>
                        <td class="modified_date">{{ ngWord.updated_at || '-' }}</td>
                        <td class="delete"><i class="fas fa-times-circle" @click="deleteNGWord(ngWord)"></i></td>
                    </tr>
                </table>
                <div class="no_content" v-show="ngWords.length <= 0">
                    <span>NGワードはありません</span>
                </div>
            </div>
        </div>
    </div>
</div>
