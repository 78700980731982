<div v-if="isActiveModal">
    <div class="overlay kw_d"></div>
    <div class="modal kw_d">
        <h2>キーワード削除</h2>
        <div class="keywords_content">
            <table class="keywords">
                <tr v-for="keyword in keywords">
                    <td>{{ keyword.keyword }}</td>
                    <td class="action"><span @click="deleteKeyword(keyword)">削除</span></td>
                </tr>
            </table>
        </div>

        <div class="modal_action">
            <div class="btn run" @click="closeDeleteKeywordsModal">完了</div>
        </div>
    </div>
</div>
