import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFoundComponent from '@/components/pages/NotFound/NotFound.vue'
import AnalyticsComponent from '@/components/pages/Analytics/MainPannel/Analytics.vue'
import PWAssetCreateComponent from '@/components/pages/PWAssetCreate/MainPannel/PWAssetCreate.vue'
import CheckComponent from '@/components/pages/Check/MainPannel/Check.vue'
import SSOComponent from '@/components/pages/SSO.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: { requiredAuth: true },
    component: PWAssetCreateComponent,
  },
  {
    path: '/kiwami',
    name: 'KiwamiTD',
    meta: { requiredAuth: true },
    redirect: {name: 'home'},
  },
  {
    path: '/pw_asset_create',
    name: 'PWAssetCreate.vue',
    meta: { requiredAuth: true },
    component: PWAssetCreateComponent,
  },
  {
    path: '/check',
    name: 'Check.vue',
    meta: { requiredAuth: true },
    component: CheckComponent,
  },
  {
    path: '/sso/callback',
    name: 'sso-callback',
    props: (route) => ({
      query: route.query,
      path_1st: 'kiwami',
    }),
    component: SSOComponent,
  },
  {
    path: '/sso/callback/:path_1st',
    name: 'sso-callback-path-1st',
    props: (route) => ({
      query: route.query,
      path_1st: route.params.path_1st,
    }),
    component: SSOComponent,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundComponent,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
