import {
  Account,
  Campaign,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface CampaignResponses extends DataServiceResponse {
  results: CampaignResponse[]
}

interface CampaignResponse extends DataServiceResponse {
  ss_id: number
  media_id: number
  account_id: string
  campaign_id: string
  campaign_name: string
}

class CampaignRepository {
  public async fetchCampaigns(data: {
      token: string,
      account: Account,
    }): Promise<Campaign[]> {
    const endpoint = `/campaign/${data.account.ss_id}/${data.account.media_id}`
    return DataService.getCache<CampaignResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((cs) => {
      return cs.results.map((c) => {
        return {
          ss_id: c.ss_id,
          media_id: c.media_id,
          account_id: c.account_id,
          campaign_id: c.campaign_id,
          campaign_name: c.campaign_name,
          track_id: c.campaign_id + ' - ' + c.campaign_name,
          label: c.campaign_id + ' - ' + c.campaign_name,
        }
      })
    })
  }
}

export const campaignRepository = new CampaignRepository()
