<div v-if="isActiveModal">
    <div class="overlay kw_d"></div>
    <div class="modal kw">
        <h2>キーワード一括削除</h2>
        <div class="register_select_wrapper">
            <div class="file_upload_area">
                <div class="file_upload">
                    <input class="upload_keyword_file" ref="inputFile" type="file" @change="setFile($event)">
                    <div class="upload_area"
                         @click="clickDragdropArea"
                         v-bind:class="{'drag_over': isFileValid, 'drag_error': isFileError}"
                         @dragover.prevent
                         @dragleave.prevent
                         @drop.prevent="setFile($event)">ドラック＆ドロップまたはクリック</div>
                    <div v-show="isFileValid">
                        <p>{{ fileName }}</p>
                        <p>選択中のアカウントにマッチする行数は{{ uploadRequestKeywordIds.length }}件です</p>
                    </div>
                    <p class="error" v-show="isFileError" v-html="errorMessage"></p>
                    <div class="btn upload" @click="downloadFormat">Format download</div>
                </div>

                <div class="modal_action">
                    <button class="btn cancel" @click="closeBulkDeleteKeywordsModal">Cancel</button>
                    <button class="btn bulk_upload_delete" v-bind:disabled="!isFileValid" @click="uploadKeywords">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>
