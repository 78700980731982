import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'reset-css/reset.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/components/common/asset/sass/import.scss'
import '@/components/common/asset/sass/multiselect/import.scss'

import useAuthorizationMiddleware from './authorization-middle'

if (process.env.NODE_ENV !== 'development') {
  useAuthorizationMiddleware()
}

createApp(App).use(router).mount('#app')
