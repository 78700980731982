<template src='./Header.html'></template>

<script lang='ts'>
import { checkState } from '@/stores/state/check/check'
import { auth } from '@/stores/authorization-state'
import {
  CmClientCompany,
  NGWord,
} from '@/stores/model/domain'
import Multiselect from '@vueform/multiselect'
import {cmClientCompanyRepository} from '@/stores/repository/client_company'
import {ngWordRepository} from '@/stores/repository/ng_word'
import {computed, defineComponent, ref} from 'vue'

const CheckHeaderComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup(_, context) {
    const fetchSetClients = (query: string) => {
      checkState.setIsLoadingClientCompanies(true)
      return cmClientCompanyRepository.fetchClientCompanies({ token: auth.token })
        .then((v: CmClientCompany[]) => {
          checkState.setIsLoadingClientCompanies(false)
          checkState.setClientCompanies(v)
      })
    }
    fetchSetClients('')

    const isLoadingClientCompanies = computed(() => {
      return checkState.loading.clientCompanies
    })

    // Multiselect用
    const selectOptions = computed(() => {
      return checkState.clientCompanyOptions
    })
    const selectedClientCompanyValue = ref<CmClientCompany | null>(null)

    const onSelectClientCompany = (clientCompany: CmClientCompany) => {
      checkState.setSelectedClientCompany(clientCompany)
      checkState.setLoadingOn()
      ngWordRepository.fetchNGWords({
        token: auth.token,
        cmClientCompanyId: clientCompany.cm_client_company_id,
      }).then((ngws: NGWord[]) => {
        checkState.setNGWords(ngws)
      }).then(() => {
        context.emit('resetInputs')
        checkState.setLoadingOff()
      })
      checkState.setLoadingOff()
    }

    return {
      isLoadingClientCompanies,
      selectOptions,
      selectedClientCompanyValue,
      onSelectClientCompany,
    }
  },
})

export default CheckHeaderComponent
</script>


