<template src='./BulkRegisterKeywordsModal.html' />
<style lang='scss' src='./BulkRegisterKeywordsModal.scss'></style>

<script lang='ts'>
import Multiselect from '@vueform/multiselect'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import {keywordRepository} from '@/stores/repository/keyword'
import {bulkUploadKeywordRepository} from '@/stores/repository/bulk_upload_keyword'
import {
  MESSAGE_UPLOAD_KEYWORD_COMPLETE,
  MESSAGE_UPLOAD_KEYWORD_FAILED,
} from '@/constants'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import {
  RequestKeywordId,
  Account,
  getMediaId,
} from '@/stores/model/domain'
import {CSVReader, Row} from '@/csv-reader'
import {computed, defineComponent, ref} from 'vue'

const BulkRegisterKeywordsModal = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    // input tag
    const inputFile = ref()
    // Upload File
    const isFileValid = ref<boolean>(false)
    const isFileError = ref<boolean>(false)
    const fileName = ref<string|null>(null)
    const errorMessage = ref<string|null>(null)
    const uploadRequestKeywordIds = ref<RequestKeywordId[]>([])
    const isActiveModal = computed(() => {
      return powerwordState.modal.bulkRegisterKeywordsModal
    })

    const closeBulkRegisterKeywordsModal = () => {
      powerwordState.setBulkRegisterKeywordsModal(false)
    }

    const resetValues = () => {
      errorMessage.value = null
      // file
      isFileValid.value = false
      isFileError.value = false
      fileName.value = null
      uploadRequestKeywordIds.value = []
    }

    const downloadFormat = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateUploadKeywordFormat()
      downloader.download(content, 'Format')
    }
    // File
    const clickDragdropArea = () => {
      // クリックしたとき、ファイル選択ダイアログが開くようにする
      inputFile.value.click()
    }

    const setFile = (event: any) => {
      const account = powerwordState.selectedAccount
      if (!account) { return }
      powerwordState.setLoadingOn()
      resetValues()
      const files = event.target.files ? event.target.files : event.dataTransfer.files
      const file: File = files[0]
      csvToRequestKeywordId(file, account)
          .then(removeEmptyRequestKeywordId)
          .then((validKeywordIds: RequestKeywordId[]) => {
        // アカウントでフィルタリング
        const filteredKeywordIds = validKeywordIds.filter((kid: RequestKeywordId) => {
          return kid.ss_id === account.ss_id
        })
        if (filteredKeywordIds.length <= 0) {
          return Promise.reject(['アカウントにマッチする行がありません。'])
        }
        return filteredKeywordIds
      }).then((requestKeywordIds: RequestKeywordId[]) => {
        // 一括 登録 時はss_keywordの存在確認する
        return bulkUploadKeywordRepository.checkExists({
          token: auth.token,
          user: auth.employeeNumber,
          requestKeywordIds,
          account,
        })
      }).then((requestKeywordIds: RequestKeywordId[]) => {
        isFileValid.value = true
        fileName.value = file.name
        uploadRequestKeywordIds.value = requestKeywordIds
        powerwordState.setLoadingOff()
      }).catch((em: string) => {
        errorMessage.value = em
        isFileError.value = true
        powerwordState.setLoadingOff()
      })
    }

    // csvのKeywordをまとめてアップロード > 登録
    const uploadKeywords = () => {
      const account = powerwordState.selectedAccount
      if (!account || uploadRequestKeywordIds.value.length <= 0) { return }
      powerwordState.setLoadingOn()
      bulkUploadKeywordRepository.post({
        token: auth.token,
        user: auth.employeeNumber,
        requestKeywordIds: uploadRequestKeywordIds.value,
        account,
      }).then((_) => {
        // 選択中（表示中）のアカウントにかかるキーワードを改めて取得
        return keywordRepository.fetchKeywords({
          token: auth.token,
          mediaId: account.media_id,
          accountId: account.account_id,
        })
      }).then((ks) => {
        resetValues()
        powerwordState.setKeywords(ks)
        powerwordState.setLoadingOff()
        alert(MESSAGE_UPLOAD_KEYWORD_COMPLETE)
      }).catch((em: string) => {
        errorMessage.value = em
        alert(MESSAGE_UPLOAD_KEYWORD_FAILED)
        powerwordState.setLoadingOff()
      })
    }

    const csvToRequestKeywordId = (file: File, account: Account) => {
      if (file) {
        const reader = new CSVReader()
        return reader.read(file, true).then((rows: Row[]) => {
          return rows.map((row) => {
            // 空行の場合や、選択中のアカウントに関連しない行はnullを返す。
            if (row.values.some((value) => value)) {
              const mediaIdRaw = parseInt(row.values[0], 10)
              if (isNaN(mediaIdRaw)) { return null }
              const mediaId = getMediaId(mediaIdRaw)
              // アカウント判定
              if (row.values[1] !== account.account_id || !mediaId || mediaId !== account.media_id) {
                return null
              }
              return {
                ss_id: account.ss_id,
                media_id: account.media_id,
                account_id: account.account_id,
                campaign_id: row.values[2],
                adgroup_id: row.values[3],
                keyword_id: row.values[4],
                // keyword: row.values[5],　// 任意項目
                // match_type: getMatchType(row.values[6]),　// 任意項目
              }
            } else { return null }
          })
        })
      } else {
        return Promise.reject(['CSVファイルロードに失敗しました'])
      }
    }

    const removeEmptyRequestKeywordId = (requestKeywordIds: Array<RequestKeywordId | null>) => {
      const filtered: RequestKeywordId[]
          = requestKeywordIds.filter((td) => td) as RequestKeywordId[]
      if (filtered.length <= 0) { return Promise.reject('キーワードIDがありません。') }
      return Promise.resolve(filtered)
    }

    return {
      inputFile,
      isFileValid,
      isFileError,
      fileName,
      errorMessage,
      uploadRequestKeywordIds,
      isActiveModal,
      closeBulkRegisterKeywordsModal,
      resetValues,
      downloadFormat,
      clickDragdropArea,
      setFile,
      uploadKeywords,
    }
  },
})
export default BulkRegisterKeywordsModal
</script>
