import { numberFormat } from '@/utils/index';
import {TitleDescription} from '@/stores/model/domain';
import {DESCRIPTION_MAX_LENGTH, MESSAGE_TEXT_MAX_LENGTH, MESSAGE_TEXT_TD_REQUIRED, TITLE_MAX_LENGTH} from '@/constants';
import { textLength } from '@/utils/validator'

export default function useTdUtils() {
  const concatTitle = (props: string[]) => {
    return props.filter((s) => s !== null).join(' - ')
  }
  const concatDescription = (props: string[]) => {
    return props.filter((s) => s !== null).join(' - ')
  }
  const ellipsisTitle = (props: string[]) => {
    const title = concatTitle(props)
    return ellipsisString(title, 25)
  }
  const ellipsisDescription = (props: string[]) => {
    const description = props.filter((s) => s !== null).join(' - ')
    return ellipsisString(description, 60)
  }

  const scoreFormat = (amount: number | null) => {
    if (!amount) { return '-' }
    return numberFormat(amount, 2, 2)
  }

  const isHigherScore = (amount: number, championAdAmount: number) => {
    return amount >= championAdAmount
  }

  const valdateTd = (td: TitleDescription) => {
    // validation
    if (!td.title_1 || !td.description_1) {
      alert(MESSAGE_TEXT_TD_REQUIRED)
      return false
    }
    if (
      (textLength(td.title_1) > TITLE_MAX_LENGTH) ||
      (textLength(td.title_2) > TITLE_MAX_LENGTH) ||
      (textLength(td.title_3) > TITLE_MAX_LENGTH) ||
      (textLength(td.description_1) > DESCRIPTION_MAX_LENGTH) ||
      (textLength(td.description_2) > DESCRIPTION_MAX_LENGTH)) {
      alert(MESSAGE_TEXT_MAX_LENGTH)
      return false
    }
    return true
  }

  // Private
  const ellipsisString = (str: string, limit: number) => {
    if (str.length > limit) {
      return str.substring(0, limit) + '…'
    } else {
      return str
    }
  }

  return {
    concatTitle,
    concatDescription,
    ellipsisTitle,
    ellipsisDescription,
    scoreFormat,
    isHigherScore,
    valdateTd,
  }
}
