export class CSVDownloader {
  public download(data: string, fileName: string) {
    // 拡張子
    const extension = '.csv';
    // BOM（UTF-16）を付与する（Excelでの文字化け対策）
    const csvString = '\ufeff' + data;
    const array = [];
    for (let i = 0; i < csvString.length; i++) {
      array.push(csvString.charCodeAt(i));
    }
    const csvContents = new Uint16Array(array);
    const blob = new Blob([csvContents], { type: 'text/csv;charset=utf-16;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + extension;
    link.click();
  }
}
