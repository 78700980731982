<template src="./BulkEstimate.html"></template>
<style lang="scss" src="@/components/pages/PWAssetCreate/BulkEstimateComponent/BulkEstimate.scss"></style>
<script lang="ts">
import {
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  getMediaId,
  getPinnedField,
} from '@/stores/model/domain'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import {defineComponent, ref} from 'vue'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import {CSVReader, Row} from '@/csv-reader'
import {
  bulkUploadRSAAdAssetRepository,
  RequestUploadRSAAdAsset,
} from '@/stores/repository/bulk_upload_rsa_ad_asset'
import {MESSAGE_SAVE_BULK_RSA_AD_ASSET_COMPLETE} from '@/constants'

const BulkEstimateComponent = defineComponent({
  components: {},
  setup() {
    const MAX_CSV_ROWS = 100000

    // InputFile element
    const inputFile = ref()
    // Upload File
    const isFileValid = ref<boolean>(false)
    const isFileError = ref<boolean>(false)
    const fileName = ref<string | null>(null)
    const errorMessage = ref<string | null>(null)
    const uploadRSAAdAssets = ref<RequestUploadRSAAdAsset[]>([])

    // method
    const resetValues = () => {
      // file
      isFileValid.value = false
      isFileError.value = false
      fileName.value = null
      errorMessage.value = null
      uploadRSAAdAssets.value = []
    }

    const downloadFormat = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateBulkEstimateRSAAdAssetFormat()
      downloader.download(content, 'BulkEstimate_Format')
    }

    // File
    const clickDragdropArea = () => {
      // クリックしたとき、ファイル選択ダイアログが開くようにする
      inputFile.value.click()
    }

    const setFile = (event: any) => {
      resetValues()
      const files = event.target.files ? event.target.files : event.dataTransfer.files
      const file: File = files[0]
      powerwordState.setLoadingOn()
      csvToUploadRSAAdAsset(file)
          .then(removeEmptyUploadRSAAdAsset)
          .then((requestUploadRSAAdAssets) => {
            if (requestUploadRSAAdAssets.length > MAX_CSV_ROWS) {
              return Promise.reject([`行数が上限（${MAX_CSV_ROWS}）を超えています`])
            }
            // ss_account、ss_adgroupの存在、及び文言重複してないか確認する
            return bulkUploadRSAAdAssetRepository.check({
              token: auth.token,
              requestUploadRSAAdAssets,
              user: auth.employeeNumber,
            })
          }).then((uras) => {
        if (uras.length <= 0) {
          return Promise.reject(['マッチする行がありません。'])
        }
        uploadRSAAdAssets.value = uras
        fileName.value = file.name
        isFileValid.value = true
        powerwordState.setLoadingOff()
      }).catch((errors: string[]) => {
        errorMessage.value = errors.toString()
        isFileError.value = true
        powerwordState.setLoadingOff()
      })
    }

    // csvのAssetをまとめてアップロード＆結果をcsvダウンロード
    const uploadDownloadRSAAdAssets = () => {
      isFileError.value = false
      errorMessage.value = ''
      powerwordState.setLoadingOn()
      bulkUploadRSAAdAssetRepository.estimate({
        token: auth.token,
        requestUploadRSAAdAssets: uploadRSAAdAssets.value,
        saveMode: false,
        user: auth.employeeNumber,
      }).then((beras) => {
        powerwordState.setLoadingOff()
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateBulkEstimateRSAAdAssetContent(beras)
        downloader.download(content, 'BulkEstimate')
      }).catch((errors: string[]) => {
        isFileError.value = true
        errorMessage.value = errors.toString()
        powerwordState.setLoadingOff()
        return
      })
    }

    // csvのAssetをまとめてアップロード＆結果をDBに保存
    const uploadSaveRSAAdAssets = () => {
      isFileError.value = false
      errorMessage.value = ''
      powerwordState.setLoadingOn()
      bulkUploadRSAAdAssetRepository.estimate({
        token: auth.token,
        requestUploadRSAAdAssets: uploadRSAAdAssets.value,
        saveMode: true,
        user: auth.employeeNumber,
      }).then((_) => {
        resetValues()
        alert(MESSAGE_SAVE_BULK_RSA_AD_ASSET_COMPLETE)
        powerwordState.setLoadingOff()
      }).catch((errors: string[]) => {
        isFileError.value = true
        errorMessage.value = errors.toString()
        powerwordState.setLoadingOff()
        return
      })
    }

    const csvToUploadRSAAdAsset = (file: File) => {
      if (file) {
        const reader = new CSVReader()
        return reader.read(file, true).then((rows: Row[]) => {
          return rows.map((row, index) => {
            // 空行の場合はnullを返す
            if (row.values.some((value) => value)) {
              const mediaIdRaw = parseInt(row.values[0], 10)
              if (isNaN(mediaIdRaw)) { return null }
              const mediaId = getMediaId(mediaIdRaw)
              if (!mediaId) { return null }

              let assetType = ASSET_TYPE.TITLE
              if (row.values[5].trim().toLowerCase() === 'description') {
                assetType = ASSET_TYPE.DESCRIPTION
              }

              const pinnedFieldRaw = parseInt(row.values[6], 10)
              const pinnedField: ASSET_PINNED_FILED | null = getPinnedField(pinnedFieldRaw, assetType)

              let isEditable = false
              if (parseInt(row.values[8], 10) === 1) {
                isEditable = true
              }

              return {
                row_num: index + 1,
                media_id: mediaId,
                account_id: row.values[1].trim(),
                campaign_id: row.values[2].trim(),
                adgroup_id: row.values[3].trim(),
                ad_number: row.values[4].trim(),
                asset_type: assetType,
                pinned_field: pinnedField,
                word: row.values[7].trim(),
                is_editable: isEditable,
              }
            } else { return null }
          })
        })
      } else {
        return Promise.reject(['CSVファイルロードに失敗しました'])
      }
    }

    const removeEmptyUploadRSAAdAsset = (uploadAssets: Array<RequestUploadRSAAdAsset | null>) => {
      const filtered: RequestUploadRSAAdAsset[]
          = uploadAssets.filter((asset) => asset) as RequestUploadRSAAdAsset[]
      return filtered
    }

    return {
      inputFile,
      isFileValid,
      isFileError,
      fileName,
      errorMessage,
      downloadFormat,
      clickDragdropArea,
      setFile,
      uploadDownloadRSAAdAssets,
      uploadSaveRSAAdAssets,
      uploadRSAAdAssets,
    }
  },
})

export default BulkEstimateComponent
</script>
