import axios, { AxiosResponse } from 'axios';

//  DataServiceのResponseであることを表すインターフェース
export interface DataServiceResponse {}

//  DataServiceにアクセスするためのモデル
export class DataServiceAccessor {
  public static async getCache<T extends DataServiceResponse>(
    data: {
      path: string,
      param: object,
      token: string,
    },
  ): Promise<T> {

    return new Promise((resolve, reject) => {
      axios.get(
        process.env.VUE_APP_DATA_CACHED_BASE_URL + data.path,
        {
          params: data.param,
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` },
        }).then((response: AxiosResponse<T>) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public static async get<T extends DataServiceResponse>(
      data: {
        path: string,
        param: object,
        token: string,
      },
    ): Promise<T> {

    return new Promise((resolve, reject) => {
      axios.get(
        process.env.VUE_APP_DATA_BASE_URL + data.path,
        {
          params: data.param,
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` },
        }).then((response: AxiosResponse<T>) => {
          resolve(response.data);
        }).catch((e) => {
          reject(e);
        });
    });
  }

  public static async post<T extends DataServiceResponse>(
    data: {
      path: string,
      param: object,
      token: string,
    },
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios.post(
        process.env.VUE_APP_DATA_BASE_URL + data.path,
        data.param,
        {
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}`},
        }).then((response: AxiosResponse<T>) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public static async put<T extends DataServiceResponse>(
    data: {
      path: string,
      param: object,
      token: string,
    },
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios.put(
        process.env.VUE_APP_DATA_BASE_URL + data.path,
        data.param,
        {
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}`},
        }).then((response: AxiosResponse<T>) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public static async delete<T extends DataServiceResponse>(
    data: {
      path: string,
      param: object,
      token: string,
    },
  ): Promise<T> {

    return new Promise((resolve, reject) => {
      axios.delete(
        process.env.VUE_APP_DATA_BASE_URL + data.path,
        {
          params: data.param,
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}`},
        }).then((response: AxiosResponse<T>) => {
          resolve(response.data);
        }).catch((e) => {
          reject(e);
        });
    });
  }
}

