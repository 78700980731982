import {
  CmClientCompany,
  Keyword,
  KEYWORD_TYPE,
  ASSET_TYPE,
  PowerWordAsset,
  PowerWordLabel,　// TODO: サーバ側から修正後に消す
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface PowerWordAssetResponses extends DataServiceResponse {
  results: PowerWordAssetResponse[]
}

interface PowerWordAssetResponse extends DataServiceResponse {
  pw_asset_id: number
  cm_client_company_id: string
  ss_id: number | null
  campaign_id: string | null
  adgroup_id: string | null
  keyword_id: string | null
  keyword: string | null
  word: string
  pw_labels: PowerWordLabel[]　// TODO: サーバ側から修正後に消す
  asset_score: number | null
  pw_asset_type: ASSET_TYPE
  pw_asset_kw_type: KEYWORD_TYPE
  is_deleted: boolean
  created_user: string
  created_at: string
  updated_user: string
  updated_at: string
  count_powerwords: number
}

interface PowerWordAssetEstimateResponse extends DataServiceResponse {
  asset_score: number
}

export function convertResponseToModel(pa: PowerWordAssetResponse): PowerWordAsset {
  return {
    pw_asset_id: pa.pw_asset_id,
    cm_client_company_id: pa.cm_client_company_id,
    ss_id: pa.ss_id,
    campaign_id: pa.campaign_id,
    adgroup_id: pa.adgroup_id,
    keyword_id: pa.keyword_id,
    keyword: pa.keyword,
    word: pa.word,
    pw_labels: pa.pw_labels,
    asset_score: pa.asset_score,
    pw_asset_type: pa.pw_asset_type,
    pw_asset_kw_type: pa.pw_asset_kw_type,
    is_deleted: pa.is_deleted,
    created_user: pa.created_user,
    created_at: pa.created_at,
    updated_user: pa.updated_user,
    updated_at: pa.updated_at,
    count_powerwords: pa.count_powerwords,
  }
}

class PowerWordAssetRepository {

  public async fetchPowerWordAssets(data: {
    token: string,
    cmClientCompanyId: string,
  }): Promise<PowerWordAsset[]> {
    const endpoint = `/powerwords/${data.cmClientCompanyId}/assets`
    return DataService.get<PowerWordAssetResponses>({
      path: endpoint,
      param: { },
      token: data.token,
    }).then((ps) => {
      return ps.results.map((pa) => {
        return convertResponseToModel(pa)
      })
    })
  }

  public async postPowerWordAsset(data: {
    token: string,
    word: string,
    keyword: Keyword,
    pwAssetKeywordType: KEYWORD_TYPE,
    pwAssetType: ASSET_TYPE,
    clientCompany: CmClientCompany,
    user: string,
  }): Promise<PowerWordAsset[]> {
    return DataService.post<PowerWordAssetResponses>({
      path: `/powerwords/assets`,
      param: {
        cm_client_company_id: data.clientCompany.cm_client_company_id,
        request_keyword_id: {
          media_id: data.keyword.media_id,
          account_id: data.keyword.account_id,
          ss_id: data.keyword.ss_id,
          campaign_id: data.keyword.campaign_id,
          adgroup_id: data.keyword.adgroup_id,
          keyword_id: data.keyword.keyword_id,
        },
        word: data.word,
        powerword_label_names: [],
        pw_asset_keyword_type: data.pwAssetKeywordType,
        pw_asset_type: data.pwAssetType,
        pw_ids: [],
        created_user: data.user,
      },
      token: data.token,
    }).then((pas) => {
      return pas.results.map((pa) => {
        return convertResponseToModel(pa)
      })
    })
  }

  public async estimatePowerWordAsset(data: {
    token: string,
    word: string,
    keyword: Keyword,
    pwAssetType: ASSET_TYPE,
    clientCompany: CmClientCompany,
    user: string,
  }): Promise<number> {
    return DataService.post<PowerWordAssetEstimateResponse>({
      path: `/powerwords/assets/estimate`,
      param: {
        cm_client_company_id: data.clientCompany.cm_client_company_id,
        request_keyword_id: {
          ss_id: data.keyword.ss_id,
          media_id: data.keyword.media_id,
          account_id: data.keyword.account_id,
          campaign_id: data.keyword.campaign_id,
          adgroup_id: data.keyword.adgroup_id,
          keyword_id: data.keyword.keyword_id,
        },
        word: data.word,
        pw_asset_type: data.pwAssetType,
        created_user: data.user,
      },
      token: data.token,
    }).then((pae) => {
      return pae.asset_score
    })
  }

  public async deletePowerWordAsset(data: {
    token: string,
    pwAsset: PowerWordAsset,
    user: string,
  }): Promise<PowerWordAsset[]> {
    return DataService.post<PowerWordAssetResponses>({
      path: `/powerwords/assets/delete`,
      param: {
        pw_asset_id: data.pwAsset.pw_asset_id,
        cm_client_company_id: data.pwAsset.cm_client_company_id,
        updated_user: data.user,
      },
      token: data.token,
    }).then((pas) => {
      return pas.results.map((pa) => {
        return convertResponseToModel(pa)
      })
    })
  }
}
export const powerWordAssetRepository = new PowerWordAssetRepository()
