<template src="./Check.html"></template>
<script lang="ts">
import HeaderComponent from '@/components/pages/Check/HeaderComponent/Header.vue'
import NGWordComponent from '@/components/pages/Check/NGWordComponent/NGWord.vue'
import {checkState} from '@/stores/state/check/check'
import {computed, defineComponent, ref} from 'vue'

export const enum CHECK_PAGE_TYPE {
  NG_WORD = 'NGWord',
}

const CheckComponent = defineComponent({
  components: {
    HeaderComponent,
    NGWordComponent,
  },
  setup() {
    const ngWord = ref()
    const pageType = ref<CHECK_PAGE_TYPE>(CHECK_PAGE_TYPE.NG_WORD)

    const isLoading = computed(() => {
      return checkState.loading.main
    })

    const isPageTypeNGWord = computed(() => {
      return pageType.value === CHECK_PAGE_TYPE.NG_WORD
    })

    const changePageTypeNGWord = () => {
      pageType.value = CHECK_PAGE_TYPE.NG_WORD
    }

    // モーダル入力リセット
    const resetInputs = () => {
      ngWord.value.resetInput()
    }

    return {
      ngWord,
      pageType,
      isLoading,
      isPageTypeNGWord,
      changePageTypeNGWord,
      resetInputs,
    }
  },
})
export default CheckComponent
</script>
