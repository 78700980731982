import {
  Account, AD_STRENGTH, ASSET_PINNED_FILED, ASSET_TYPE, BulkDownloadRSAAd, MEDIA_ID,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface ErrorAssetAdAdgId extends DataServiceResponse {
  adgroup_id: string
  ad_id: string
}

interface BulkRSAAdResponses extends DataServiceResponse {
  results: BulkRSAAdResponse[]
  error_adgroup_ids: string[]
  error_asset_ad_adg_ids: ErrorAssetAdAdgId[]
}

interface BulkRSAAdResponse {
  ss_id: number
  account_id: string
  media_id: MEDIA_ID
  campaign_id: string
  adgroup_id: string
  ad_id: string | null
  word: string | null
  asset_type: ASSET_TYPE | null
  pinned_field: ASSET_PINNED_FILED | null
  ad_strength: AD_STRENGTH | null
  score: number | null
}

class BulkUploadRSAAdRepository {

  public async download(data: {
    token: string,
    user: string,
    account: Account,
    adgIds: string[],
  }): Promise<BulkDownloadRSAAd[]> {
    const endpoint = `/rsa_ad/bulk/download`
    return DataService.post<BulkRSAAdResponses>({
      path: endpoint,
      param: {
        ss_id: data.account.ss_id,
        account_id: data.account.account_id,
        media_id: data.account.media_id,
        adgroup_ids: data.adgIds,
        user: data.user,
      },
      token: data.token,
    }).then((bras) => {
      if (bras.error_adgroup_ids.length +
        bras.error_asset_ad_adg_ids.length <= 0) {
        return bras.results.map((bra) => {
          return {
            ss_id: bra.ss_id,
            account_id: bra.account_id,
            media_id: bra.media_id,
            campaign_id: bra.campaign_id,
            adgroup_id: bra.adgroup_id,
            ad_id: bra.ad_id,
            word: bra.word,
            asset_type: bra.asset_type,
            pinned_field: bra.pinned_field,
            ad_strength: bra.ad_strength,
            score: bra.score,
          }
        })
      }
      let errorInvalidAdGroupMessage = ''
      if (bras.error_adgroup_ids.length > 0) {
        errorInvalidAdGroupMessage = '以下のAdGroupについてはAdGroupが存在しないか、同期が完了していません。<br/>' +
          bras.error_adgroup_ids.join('<br/>') + '<br/>'
      }
      let errorInvalidAssetAdMessage = ''
      if (bras.error_asset_ad_adg_ids.length > 0) {
        errorInvalidAssetAdMessage = '以下のAdについてはAseetが存在しないか、同期が完了していません。<br/>' +
          bras.error_asset_ad_adg_ids.map((e) => `AdgId: ${e.adgroup_id}　AdId: ${e.ad_id}`).join('<br/>') + '<br/>'
      }
      return Promise.reject(errorInvalidAdGroupMessage +
        errorInvalidAssetAdMessage)
    })
  }
}

export const bulkUploadRSAAdRepository = new BulkUploadRSAAdRepository()
