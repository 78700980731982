<div>
    <header>
        <div class="logo"></div>
        <div class="client_select">
            <div v-show="isLoadingClientCompanies" class="select_mask">
                <div class="select_loading"></div>
            </div>

            <Multiselect
                    class=""
                    placeholder="クライアント名で検索"
                    v-model="selectedClientCompanyValue"
                    value-prop="value"
                    :options="selectOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectClientCompany">
            </Multiselect>
        </div>

        <div class="header_btn">
            <div class="btn current">Asset Create</div>
            <a class="btn" href="/check">Check</a>
        </div>
    </header>
</div>
