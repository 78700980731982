<div class="main_pannel">
    <HeaderComponent @resetInputs="resetInputs"/>
    <article>
        <div class="cr_main">
            <div class="cr_tabs">
                <div class="btn" v-bind:class="{ current: isPageTypeNGWord }" @click="changePageTypeNGWord">NGワード</div>
            </div>

            <NGWordComponent v-show="isPageTypeNGWord" ref="ngWord"/>
        </div>
    </article>
    <div class="loading_screen" v-if="isLoading">
        <div class="loading_image"></div>
    </div>
</div>
