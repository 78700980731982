<div v-if="isActiveModal">
    <div class="overlay kw_d"></div>
    <div class="modal kw">
        <h2>キーワード登録</h2>
        <div class="">
            <Multiselect
                    class=""
                    placeholder="キャンペーン・アドグループ名で検索"
                    v-model="selectedAdGroupValue"
                    value-prop="value"
                    :options="selectAdGroupOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectAdGroup">
            </Multiselect>
        </div>

        <div v-if="selectedAdGroup != null" class="sort_key">
            <div class="keyword_filter_wrap">
                <div class="select_mask" v-show="isLoadingKeywords">
                    <div class="select_loading"></div>
                </div>
                <textarea v-model="rawFilter" placeholder="キーワード名、IDで絞り込み"></textarea>
                <p class="attention">複数でOR検索する場合は改行区切り</p>
                <div class="action_btn">
                    <div class="btn" @click="onClickFilterKeywords">絞り込み</div>
                </div>
            </div>

            <div class="register-table-wrap">
                <div class="select_mask" v-show="isLoadingKeywords">
                    <div class="select_loading"></div>
                </div>

                <div class="cr_table">
                    <div class="table_header">
                        <table>
                            <tr>
                                <th class="kw_id">キーワードID</th>
                                <th>キーワード</th>
                                <th class="status">ステータス</th>
                                <th class="match_type">マッチタイプ</th>
                                <th class="register"></th>
                            </tr>
                        </table>
                    </div>

<!-- VueGoodTableが使えないので普通のテーブル使うことにした TODO:// スタイル-->
                    <div class="table_scroll">
                        <table>
                            <tr v-for="kw in candidateKeywordsForViews">
                                <td class="kw_id">{{ kw.keyword_id }}</td>
                                <td>{{ kw.keyword }}</td>
                                <td class="status">{{ kw.status }}</td>
                                <td class="match_type">{{ kw.match_type }}</td>
                                <td class="register">
                                    <span v-if="isRegisteredKeyword(kw)">
                                        <span>登録済</span>
                                    </span>
                                    <span v-else>
                                        <span class="btn upload" @click="registerKeyword(kw)">登録</span>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal_action">
            <div class="btn cancel" @click="closeRegisterKeywordsModal">Close</div>
        </div>
    </div>
</div>
