<div class="main_pannel">
    <HeaderComponent/>
    <article>
        <div class="cr_main">
            <div class="cr_tabs">
                <div class="btn" v-bind:class="{ current: pageType === 'ProduceRSA' }" @click="changePageTypeProduceRSA">RSA制作</div>
                <div class="btn" v-bind:class="{ current: pageType === 'BulkEstimate' }" @click="changePageTypeBulkEstimate">一括評価</div>
                <div class="btn" v-bind:class="{ current: pageType === 'BulkRSAAd' }" @click="changePageTypeBulkRSAAd">配信RSAAd一括DL</div>
                <div class="btn" v-bind:class="{ current: pageType === 'BulkPreCombinedAsset' }" @click="changePageTypeBulkPreCombinedAsset">事前組み合わせAsset一括DL</div>
                <div class="btn" v-bind:class="{ current: pageType === 'AssetRegistration' }" @click="changePageTypeAssetRegistration">Asset</div>
            </div>

            <AccountKeywordComponent v-show="!!selectedClient && pageType === 'AssetRegistration'" @resetInputs="resetInputs"/>
            <ProduceRSAComponent v-show="pageType === 'ProduceRSA'" ref="produceRSA"/>
            <BulkEstimateComponent v-show="pageType === 'BulkEstimate'" ref="BulkEstimate"/>
            <BulkRSAAdComponent v-show="pageType === 'BulkRSAAd'" ref="BulkRSAAd"/>
            <BulkPreCombinedAssetComponent v-show="pageType === 'BulkPreCombinedAsset'" ref="BulkPreCombinedAsset"/>
            <AssetRegistrationComponent v-show="pageType === 'AssetRegistration'" ref="assetRegistration"/>

            <!-- キーワード選択時に表示される情報 -->
            <div v-if="canShowKeywordInfo" class="keyword_detail">
                <table>
                    <tbody><tr>
                        <th>Campaign:</th>
                        <td>{{ selectedKeyword.campaign_name }}</td>
                    </tr>
                    <tr>
                        <th>Ad Group:</th>
                        <td>{{ selectedKeyword.adgroup_name }}</td>
                    </tr>
                    <tr>
                        <th>Keyword:</th>
                        <td>{{ selectedKeyword.keyword }}</td>
                    </tr>
                    <tr>
                        <th>Match Type:</th>
                        <td>{{ selectedKeyword.match_type }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>

    <!--Modal-->
    <DeleteKeywordsModal/>
    <BulkRegisterKeywordsModal/>
    <RegisterKeywordsModal/>
    <BulkDeleteKeywordsModal/>

    <div class="loading_screen" v-if="isLoading">
        <div class="loading_image"></div>
    </div>
</div>
