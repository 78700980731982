<div>
    <h2><i class="fas fa-caret-right"></i>一括評価</h2>
    <div class="file_upload">
        <input class="upload_file" ref="inputFile" type="file" @change="setFile($event)">
        <div class="upload_area"
             @click="clickDragdropArea"
             v-bind:class="{'drag_over': isFileValid, 'drag_error': isFileError}"
             @dragover.prevent
             @dragleave.prevent
             @drop.prevent="setFile($event)">ドラック＆ドロップまたはクリック</div>
        <div v-show="isFileValid">
            <p>{{ fileName }}</p>
            <p>マッチする行数は{{ uploadRSAAdAssets.length }}件です</p>
        </div>
        <p class="error" v-show="isFileError" v-html="errorMessage"></p>
        <div class="btn upload" @click="downloadFormat">Format download</div>
    </div>

    <div class="btn_action">
        <button class="btn run file_upload" v-bind:disabled="!isFileValid" @click="uploadDownloadRSAAdAssets">スコア判定</button>
        <button class="btn run file_upload" v-bind:disabled="!isFileValid" @click="uploadSaveRSAAdAssets">登録</button>
    </div>
</div>
