export interface Row {
  id: number;
  values: string[];
}

export class CSVReader {
  public read(csv: File, isHeader = true) {
    const CSV = require('comma-separated-values')
    const delimiter = '\t'

    return new Promise(
      (resolve: (values: Row[]) => void, reject: () => void) => {
        const fr = new FileReader()
        fr.onload = (ev) => {
          if (!ev.target || !ev.target.result) {
            return reject();
          }
          let allLines = new CSV(ev.target.result, {
            header: false,
            cellDelimiter: delimiter,
          }).parse();
          if (isHeader) {
            allLines = allLines.slice(1);
          }
          // Reading line and return as Row[]
          const rows: Row[] = allLines.map((vs: string[], i: number) => {
            return {
              id: i,
              values: vs,
            };
          });
          return resolve(rows);
        };
        fr.readAsText(csv, 'utf-8');
      },
    );
  }
}
