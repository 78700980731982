<template src='./DeleteKeywordsModal.html' />
<style lang='scss' src='./DeleteKeywordsModal.scss'></style>
<script lang='ts'>
import { Keyword } from '@/stores/model/domain'
import { powerwordState } from '@/stores/state/powerword/powerword'
import { auth } from '@/stores/authorization-state'
import { keywordRepository } from '@/stores/repository/keyword'
import {defineComponent, computed} from 'vue'

const DeleteKeywordsModal = defineComponent({
  setup() {
    const isActiveModal = computed(() => {
      return powerwordState.modal.deleteKeywordsModal
    })

    const keywords = computed(() => {
      return powerwordState.keywords
    })

    const closeDeleteKeywordsModal = () => {
      powerwordState.setDeleteKeywordsModal(false)
    }

    const deleteKeyword = (targetKeyword: Keyword) => {
      keywordRepository.deleteKeyword({
        token: auth.token,
        user: auth.employeeNumber,
        keyword: targetKeyword,
      }).then((kws: Keyword[]) => {
        powerwordState.setKeywords(kws)
      })
    }

    return {
      isActiveModal,
      keywords,
      closeDeleteKeywordsModal,
      deleteKeyword,
    }
  },
})

export default DeleteKeywordsModal
</script>
