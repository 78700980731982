<template>
  <div class="llm_asset_generator">
    <div class="generator_input_wrap">
      <div class="generator_input keyword_select">
        <label>キーワードを選択（必須）</label>
        <Multiselect
            class=""
            placeholder="キーワード名で検索"
            v-model="selectedKeyword"
            value-prop="value"
            :options="selectKeywordOptions"
            label="label"
            track-by="track_by"
            :searchable="true"
            :option-height="20"
            :limit="1000">
        </Multiselect>
        <div class="kw" v-if="!!selectedKeyword">CMPID:{{ selectedKeyword.campaign_id }} ADGID:{{ selectedKeyword.adgroup_id }} KWID:{{ selectedKeyword.keyword_id }}</div>
      </div>

      <div class="generator_input input_prompt">
        <label>訴求を追加（任意）</label>
        <textarea v-model="inputPrompt" placeholder="訴求を追加"></textarea>
      </div>

      <div class="generator_input select_td">
        <input type="radio" name="td_select_title" id="td_select_title" v-model="inputTitleDescription" value="Title">
        <label for="td_select_title" class=""><i class="fas fa-circle"></i>タイトル</label>
        <input type="radio" name="td_select_description" id="td_select_description" v-model="inputTitleDescription" value="Description">
        <label for="td_select_description" class=""><i class="fas fa-circle"></i>説明文</label>
      </div>

      <div>
        <button class="btn upload" v-bind:disabled="!selectedKeyword" @click="onClickGenerate">生成する</button>
      </div>
    </div>

    <div v-show="!!errorMessage" class="error">
      <span v-html="errorMessage"></span>
    </div>

    <div class="cr_table">
      <div class="table_header">
        <table>
          <tr>
            <th class="rsa_kw">キーワード</th>
            <th class="asset_type">タイプ</th>
            <th class="rsa_asset">アセット</th>
            <th class="rsa_check">
              <div class="checkbox">
                <input id="llm_box_all_enabled"
                       type="checkbox"
                       v-model="llmcaAllEnabled"
                       @click="onClickCheckAll()">
                <label for="llm_box_all_enabled"></label>
              </div>
            </th>
            <th class="clipboard"></th>
            <th class="count_number"></th>
            <th class="ng_word">NGワード</th>
          </tr>
        </table>
      </div>

      <div class="table_scroll">
        <table>
          <tr v-for="(llmca, index) in llmCandidateAssets">
            <td class="rsa_kw">{{ llmca.keyword }}</td>
            <td class="asset_type">{{ assetTypeFormat(llmca.asset_type) }}</td>
            <td class="rsa_asset">
              <span class="ng_word_tagged" v-html="llmca.asset_text_ng_tagged" />
              <p v-show="llmca.ng_check_types.has(NG_TYPE.AVAILABLE_REPLACE)" class="replaced_text">{{ llmca.asset_text }}</p>
            </td>
            <td class="rsa_check">
              <div class="checkbox">
                {{ box_id = "llm_box" + index }}
                <input :id="box_id"
                       type="checkbox"
                       v-model="llmca.is_editable"
                       @change="onChangeCheckbox()">
                <label :for="box_id"></label>
              </div>
            </td>
            <td class="clipboard">
              <div class="action">
                <button class="btn clipboard" @click="onClickClipBoard(llmca.asset_text)"><i class="fas fa-clipboard-list"></i></button>
              </div>
            </td>
            <td class="count_number">
              <span>{{ textFullLength(llmca.asset_text) }}</span>文字
            </td>
            <td class="ng_word">
              <div v-for="(ngCheckType) in llmca.ng_check_types">
                {{ ngCheckTypeFormat(ngCheckType) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ASSET_TYPE, BannedAsset,
  CandidateAsset,
  ChampionRSAAd,
  KeywordImpConv,
  KeywordImpConvOption,
  LLMAsset,
  NG_TYPE,
  NGWord,
} from '@/stores/model/domain'
import Multiselect from '@vueform/multiselect'
import {ref} from 'vue'
import {llmAssetRepository} from '@/stores/repository/llm_asset'
import {auth} from '@/stores/authorization-state'
import {textLength} from '@/utils/validator'
import {Format} from '@/utils'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {checkNGWord} from '@/utils/rsa/ngword_check';

const enum SELECT_TITLE_DESCRIPTION {
  TITLE = 'Title',
  DESCRIPTION = 'Description',
}

const selectKeywordOptions = ref< KeywordImpConvOption[] >([])
const selectedKeyword = ref<KeywordImpConv | null>(null)
const inputPrompt = ref<string | null>(null)
const inputTitleDescription = ref < SELECT_TITLE_DESCRIPTION > (SELECT_TITLE_DESCRIPTION.TITLE)
const llmCandidateAssets = ref <CandidateAsset[]> ([])

const llmcaAllEnabled = ref<boolean>(false)

const errorMessage = ref < string | null > (null)
const ngWords = ref <NGWord[]> ([])
const bannedAssets = ref <BannedAsset[]> ([])

const championRSAAd = ref<ChampionRSAAd | null>(null)
const textFullLength = (str: string | null) => {
  return textLength(str)
}
const ngCheckTypeFormat = (ngType: NG_TYPE) => {
  return Format.ngCheckType(ngType)
}
const assetTypeFormat = (assetType: ASSET_TYPE) => {
  return Format.assetType(assetType)
}

const onClickGenerate = () => {
  const keyword = selectedKeyword.value
  const rsaAd = championRSAAd.value
  const assetType = inputTitleDescription.value == SELECT_TITLE_DESCRIPTION.TITLE ? ASSET_TYPE.TITLE : ASSET_TYPE.DESCRIPTION
  const prompt = inputPrompt.value
  if (!keyword || !rsaAd) { return }

  const currentAssets = assetType == ASSET_TYPE.TITLE ?
      rsaAd.title_assets.map((asset) => { return asset.word }) :
      rsaAd.description_assets.map((asset) => { return asset.word })

  if (currentAssets.length <= 0) { return }

  powerwordState.setLoadingOn()
  llmAssetRepository.fetchLLMAssets({
    token: auth.token,
    keyword,
    prompt,
    lp_url: rsaAd.final_url,
    currentAssets,
    assetType,
  }).then((llmas) => {
    const cas = llmas.map(convertToCandidateAsset)
    const unbannedCas = removeBannedAssetFromCandidates(cas)
    llmCandidateAssets.value = llmCandidateAssets.value.concat(unbannedCas)
    return
  }).then(() => {
    resetCheckNGWords()
    return
  }).then(() => {
    reCheckNGWords()
    updateParentLLMAssets();
    powerwordState.setLoadingOff()
  }).catch((e) => {
    powerwordState.setLoadingOff()
    errorMessage.value = 'LLMアセットの取得に失敗しました<br/>' + e.toString()
  })
}

// LLMAsset => CandidateAssetに変換
const convertToCandidateAsset = (llmAsset: LLMAsset): CandidateAsset => {
  return {
    generator_asset_id: null,
    ss_id: llmAsset.keyword.ss_id,
    media_id: llmAsset.keyword.media_id,
    campaign_id: llmAsset.keyword.campaign_id,
    adgroup_id: llmAsset.keyword.adgroup_id,
    keyword_id: llmAsset.keyword.keyword_id,
    keyword: llmAsset.keyword.keyword,
    match_type: llmAsset.keyword.match_type,
    asset_type: llmAsset.asset_type,
    asset_text: llmAsset.word,
    generated_date: null,
    asset_text_default: llmAsset.word,
    asset_text_ng_tagged: llmAsset.word,
    is_editable: false,
    pinned_field: null,
    ng_check_types: new Set(),
  }
}

// CheckNGWords初期化
const resetCheckNGWords = () => {
  llmCandidateAssets.value = llmCandidateAssets.value.map((ca) => {
    ca.ng_check_types = new Set()
    ca.asset_text_ng_tagged = ca.asset_text
    return ca
  })
}

// NgWord再適用
const reCheckNGWords = () => {
  const ngws = ngWords.value
  ngws.forEach((ngw) => {
    llmCandidateAssets.value = llmCandidateAssets.value.map((ca) => {
      return checkNGWord(ngw, ca)
    })
  })
}

// 新しくfetchしたllmアセットに対してbanned_assetsを反映させる
const removeBannedAssetFromCandidates = (cas: CandidateAsset[]): CandidateAsset[] => {
  const bas = bannedAssets.value
  return cas.flatMap((ca) => {
    if (bas.some((ba) => ca.asset_text_default.match(ba.asset_text))) {
      return []
    }
    else { return ca }
  }) as CandidateAsset[]
}

// クリップボード
const onClickClipBoard = (word: string) => {
  if (!navigator.clipboard) { return }
  return navigator.clipboard.writeText(word)
}

// llm編集チェック
const onClickCheckAll = () => {
  if (llmCandidateAssets.value.length <= 0) { return }

  powerwordState.setLoadingOn()
  // 全てチェック入っていたらoffにする
  //　そうでない場合は全てonにする
  if (llmCandidateAssets.value.every((llma) => llma.is_editable)) {
    llmCandidateAssets.value = llmCandidateAssets.value.map((llma) => {
      llma.is_editable = false
      return llma
    })
    llmcaAllEnabled.value = false
  } else {
    llmCandidateAssets.value = llmCandidateAssets.value.map((llma) => {
      llma.is_editable = true
      return llma
    })
    llmcaAllEnabled.value = true
  }
  updateParentLLMAssets()
  powerwordState.setLoadingOff()
}

const onChangeCheckbox = () => {
  updateParentLLMAssets()
}

const emit = defineEmits(['updateLLMAssets'])
const updateParentLLMAssets = () => {
  emit('updateLLMAssets', llmCandidateAssets.value)
}

const resetInputs = (kws: KeywordImpConv[], ngws: NGWord[], bas: BannedAsset[], rsaAd: ChampionRSAAd | null ) => {
  selectKeywordOptions.value = kws.map((kw) => {
    return {
      value: kw,
      label: `【${kw. keyword_id}】${kw.keyword}（${kw.imp}, ${kw.conv}）`,
      track_by: `${kw.campaign_id}：${kw.campaign_name}：${kw.adgroup_id}：${kw.adgroup_name}：${kw.keyword_id}：${kw.keyword}`,
    }
  })
  selectedKeyword.value = null
  llmCandidateAssets.value = []
  ngWords.value = ngws
  bannedAssets.value = bas
  championRSAAd.value = rsaAd
  updateParentLLMAssets()
}
defineExpose({
  resetInputs,
})

</script>

<style lang="scss" src="@/components/pages/PWAssetCreate/ProduceRSAComponent/LLMAssetGeneratorComponent/LLMAssetGenerator.scss"></style>
