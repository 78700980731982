// 全角バイトを考慮して、文字数バリデーションしてる
export function textLength(str: string | null): number {
    if (!str) {
      return 0;
    }
    let len = 0;
    const replaced = str.replace(/{countdown\(.*?\)\s*}/ig, '') // countdownタグ削除
      .replace(/{customizer\.\w*\s*:\s*(.*?)\s*}/ig, '$1') // customizerタグをdefaultタグに置き換え
      .replace(/{[\w\(\)\s]+:\s*(.*?)\s*}/g, '$1') // keyword、locationなどはdefaultタグに置き換え
    const escaped = escape(replaced);
    for (let i = 0; i < escaped.length; i++, len++) {
      if (escaped.charAt(i) === '%') {
        if (escaped.charAt(++i) === 'u') {
          i += 3;
          len++;
        }
        i++;
      }
    }
    return len;
}
