import {reactive} from 'vue'
import axios from 'axios'

interface AuthorizationState {
  token: string,
  refreshToken: string,
  employeeNumber: string,
  setToken: (token: string) => void,
  setRefreshToken: (token: string) => void,
  setEmployeeNumber: (employeeNumber: string) => void,
  requestToken: (code: string) => Promise<any>,
}

export const auth = reactive<AuthorizationState>({
  token: '',
  refreshToken: '',
  employeeNumber: 'SYSTEM',
  setToken: (token: string) => {
    auth.token = token
  },
  setRefreshToken: (token: string) => {
    auth.refreshToken = token
  },
  setEmployeeNumber: (employeeNumber: string) => {
    auth.employeeNumber = employeeNumber
  },
  requestToken: (code: string) => {
    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('client_id', process.env.VUE_APP_CASSO_CLIENT_ID!)
    params.append('client_secret', process.env.VUE_APP_CASSO_CLIENT_SECRET!)
    params.append('redirect_uri', location.origin + location.pathname)

    return new Promise((resolve, reject) => {
      axios.post(
        '/sso/request_token',
        params,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
      ).then((response) => {
        auth.setToken(response.data.id_token)
        auth.setRefreshToken(response.data.refresh_token)
        const userInfo = response.data.id_token.split('.')[1]
        auth.setEmployeeNumber(JSON.parse(window.atob(userInfo)).sub)
        resolve(response.data.id_token);
      }).catch((error) => {
        reject(error)
      })
    })
  },
})
