<div class="account_keyword_select">
    <div class="">
        <Multiselect
                class=""
                placeholder="アカウント名で検索"
                v-model="selectedAccountValue"
                value-prop="value"
                :options="selectAccountOptions"
                label="label"
                track-by="track_by"
                :searchable="true"
                :option-height="20"
                :limit="1000"
                @select="onSelectAccount">
        </Multiselect>
        <div class="ssid" v-if="!!selectedAccount">SSID:{{ selectedAccount.ss_id }}</div>
    </div>

    <div class="main_action cr_main_action">
        <span class="no_keyword" v-show="keywords.length<=0">キーワード未登録</span>

        <div class="cr_keywords">
            <div class="keyword_label">

                <div class="kw_box">
                    <div class="kw_block" v-for="keyword in keywords">
                        <input type="radio" name="keywords" :id="keyword.key" @change="onChangeKeyword(keyword)" :checked="isCheckedKeyword(keyword)">
                        <label :for="keyword.key" class="kw_tooltip">{{ keyword.daily_budget ? '' : '⚠' }} {{ keyword.keyword }}
                            <i v-if="isDuplicatedKeyword(keyword)" class="fas fa-clone"></i>
                            <span class="kw_tooltiptext">
                                <span class="kw_tooltip_detail">
                                   <span class="name">[AdGroup]</span>
                                   <span class="value">{{ keyword.adgroup_name }}</span>
                                   <span class="name">[Keyword]</span>
                                   <span class="value">{{ keyword.keyword }}</span>
                                   <span class="name">[Match type]</span>
                                   <span class="value">{{ keyword.match_type }}</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="keyword_action" v-if="selectedAccount">
            <span>キーワード：</span>
            <div class="btn keyword upload" @click="openRegisterKeywordsModal">登録</div>
            <div class="btn keyword bulk_upload" @click="openBulkRegisterKeywordsModal">一括登録</div>
            <div class="btn keyword upload_delete" @click="openDeleteKeywordsModal">削除</div>
            <div class="btn keyword bulk_upload_delete" @click="openBulkDeleteKeywordsModal">一括削除</div>
        </div>
    </div>
</div>
