export const TITLE_MAX_LENGTH = 30;
export const DESCRIPTION_MAX_LENGTH = 90;

export const MESSAGE_EMPTY_STATE_GETTING  = '取得中...';
export const MESSAGE_EMPTY_STATE_NOT_EXIST = 'マッチするキーワードが存在しません。';

export const MESSAGE_TEXT_MAX_LENGTH = '最大文字数を超えています。';
export const MESSAGE_TEXT_TD_REQUIRED = 'Title1、Description1は必須です。';

export const MESSAGE_PLEASE_SPECIFY_ACCOUNT = 'アカウントを指定してください。';
export const MESSAGE_FAILED_TO_FETCH_DATA = 'データ取得に失敗しました。';
export const MESSAGE_FAILED_TO_DELETE_DATA = 'データ削除に失敗しました。';


// PowerWord
export const MESSAGE_PLEASE_INPUT_ASSET = 'アセットを入力してください。';
export const MESSAGE_POST_ASSET_FAILED = 'アセットの保存に失敗しました。';
// UploadKeyword
export const MESSAGE_UPLOAD_KEYWORD_COMPLETE = 'キーワードのアップロードが完了しました';
export const MESSAGE_UPLOAD_KEYWORD_FAILED = 'キーワードのアップロードに失敗しました';
export const MESSAGE_DELETE_KEYWORD_COMPLETE = 'キーワードの削除が完了しました';
export const MESSAGE_DELETE_KEYWORD_FAILED = 'キーワードの削除に失敗しました';

// CreatorAssets
export const MESSAGE_UPLOAD_CREATER_ASSETS_COMPLETE = 'CreatorAssetのアップロードが完了しました';
// NGWord
export const MESSAGE_UPLOAD_NG_WORDS_COMPLETE = 'NGワードのアップロードが完了しました';
// BulkRSAAdAsset
export const MESSAGE_SAVE_BULK_RSA_AD_ASSET_COMPLETE = 'RSAAssetの保存が完了しました';
