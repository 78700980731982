import {
  Account,
  Money,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface AccountsResponse extends DataServiceResponse {
  results: AccountResponse[]
}

interface AccountResponse extends DataServiceResponse {
  cm_client_company_id: string
  ss_id: number
  media_id: number
  account_id: string
  account_name: string
  cost: Money
  track_id: string
  label: string
}

class AccountRepository {
  public async fetchAccounts(data: { token: string, cmClientCompanyId: string }): Promise<Account[]> {
    const endpoint = `/accounts/${data.cmClientCompanyId}`
    return DataService.getCache<AccountsResponse>({
      path: endpoint,
      param: { },
      token: data.token,
    }).then((as) => {
      return as.results.map((a) => {
        return {
          cm_client_company_id: a.cm_client_company_id,
          ss_id: a.ss_id,
          media_id: a.media_id,
          account_id: a.account_id,
          account_name: a.account_name,
          cost: {
            amount: a.cost.amount,
            currency: a.cost.currency,
          },
          track_id: a.account_id + ':' + a.ss_id + ':' + a.account_name,
          label: a.account_id + '(' + a.ss_id + ') - ' + a.account_name,
        }
      })
    })
  }
}

export const accountRepository = new AccountRepository()
