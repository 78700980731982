import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'
import {BannedAsset, NGWord} from '@/stores/model/domain';

interface BannedAssetResponses extends DataServiceResponse {
  results: BannedAssetResponse[]
}

interface BannedAssetResponse extends DataServiceResponse {
  banned_asset_id: number
  cm_client_company_id: string
  asset_text: string
  reason: string
  generator_asset_id: number | null
  created_user: string
  created_at: string
  updated_user: string | null
  updated_at: string | null
}

interface PostResponse extends DataServiceResponse {
}

function convertResponseToModel(bar: BannedAssetResponse): BannedAsset {
  return {
    banned_asset_id: bar.banned_asset_id,
    cm_client_company_id: bar.cm_client_company_id,
    asset_text: bar.asset_text,
    reason: bar.reason,
    generator_asset_id: bar.generator_asset_id,
    created_user: bar.created_user,
    created_at: bar.created_at,
    updated_user: bar.updated_user,
    updated_at: bar.updated_at,
  }
}

class BannedAssetRepository {
  public async fetchBannedAssets(data: {
    token: string,
    cmClientCompanyId: string,
  }): Promise<BannedAsset[]> {
    const endpoint = `/banned_assets/${data.cmClientCompanyId}`
    return DataService.get<BannedAssetResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((bars) => {
      return bars.results.map((bar) => {
        return convertResponseToModel(bar)
      })
    })
  }

  public async post(data: {
    token: string,
    cm_client_company_id: string,
    asset_text: string,
    reason: string,
    generator_asset_id: number | null,
    created_user: string,
  }): Promise<void> {
    const endpoint = `/banned_assets`
    return DataService.post<PostResponse>({
      path: endpoint,
      param: {
        cm_client_company_id: data.cm_client_company_id,
        asset_text: data.asset_text,
        reason: data.reason,
        generator_asset_id: data.generator_asset_id,
        created_user: data.created_user,
      },
      token: data.token,
    }).then((_) => { return })
  }
}

  export const bannedAssetRepository = new BannedAssetRepository()
