import router from './router'
import { auth } from '@/stores/authorization-state'

export default function useAuthorizationMiddleWare() {
  const jwtDecoder = require('jwt-decode')
  function redirect() {
    let pathname = location.pathname
    if (pathname === '') {
      pathname = '/'
    } else if (! pathname.startsWith('/')) {
      pathname = '/' + pathname
    }
    const redirectUri = encodeURIComponent(location.origin + '/sso/callback' + pathname)
    window.location.href = `https://casso.services.isca.jp/as/authorization.oauth2?`
    + `response_type=code&client_id=${process.env.VUE_APP_CASSO_CLIENT_ID}`
    + `&redirect_uri=${redirectUri}&scope=openid%20email`
  }
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiredAuth)) {
      if (auth.token === '') {
        redirect()
      } else {
        const currentTimestamp = Math.round(new Date().getTime() / 1000)
        const jwt = jwtDecoder.jwtDecode(auth.token)
        if (jwt.aud === process.env.VUE_APP_CASSO_CLIENT_ID) {
          next()
        } else {
          redirect()
        }
      }
    } else {
      next()
    }
  })
}
