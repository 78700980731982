<template src='./ProduceRSAHistoryModal.html' />
<style lang="scss" src="@/components/pages/PWAssetCreate/Modals/ProduceRSAHistoryModal/ProduceRSAHistoryModal.scss"></style>
<script lang='ts'>
import {
  Account,
  AdGroup,
  RSAProduct,
} from '@/stores/model/domain'
import {computed, defineComponent, ref} from 'vue'
import Multiselect from '@vueform/multiselect'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {rsaProductRepository} from '@/stores/repository/rsa_product'
import {auth} from '@/stores/authorization-state'
import useTdUtils from '@/utils/td-utils'

const ProduceRSAHistoryModal = defineComponent({
  components: {
    Multiselect,
  },
  setup(_, context) {
    const rsaProducts = ref<RSAProduct[]>([])
    const isActiveModal = computed(() => {
      return powerwordState.modal.produceRSAHistoryModal
    })

    const {
      scoreFormat,
    } = useTdUtils()

    const reloadHistory = (account: Account, adgroup: AdGroup | null) => {
      powerwordState.setLoadingOn()
      rsaProductRepository.fetchRSAProductsByAMKey({
        token: auth.token,
        media_id: account.media_id,
        account_id: account.account_id,
        campaign_id: adgroup ? adgroup.campaign_id : '',
        adgroup_id: adgroup ? adgroup.adgroup_id : '',
      }).then((rps) => {
        rsaProducts.value = rps
        powerwordState.setLoadingOff()
      }).catch(() => {
        powerwordState.setLoadingOff()
        powerwordState.setProduceRSAHistoryModal(false)
        alert('RSA履歴の取得に失敗しました')
      })

    }

    const onClickEditRSAProduct = (rsaProduct: RSAProduct) => {
      if (!powerwordState.selectedAdGroup) { return }
      powerwordState.setLoadingOn()
      rsaProductRepository.fetchRSAProductByPK({
        token: auth.token,
        rsa_product_id: rsaProduct.rsa_product_id,
      }).then((rp) => {
        context.emit('editRSAProduct', rp)
        powerwordState.setLoadingOff()
        powerwordState.setProduceRSAHistoryModal(false)
      })
    }

    const closeProduceRSAHistoryModal = () => {
      powerwordState.setProduceRSAHistoryModal(false)
    }

    return {
      isActiveModal,
      rsaProducts,
      reloadHistory,
      onClickEditRSAProduct,
      closeProduceRSAHistoryModal,
      scoreFormat,
    }
  },
})
export default ProduceRSAHistoryModal
</script>
