<template src='./RegisterKeywordsModal.html' />
<style lang='scss' src='./RegisterKeywordsModal.scss'></style>
<script lang='ts'>
import Multiselect from '@vueform/multiselect'
import {Format} from '@/utils/index'
import {
  AdGroup,
  Keyword,
  MATCH_TYPE,
} from '@/stores/model/domain'
import { powerwordState } from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import {keywordRepository} from '@/stores/repository/keyword'
import {MESSAGE_EMPTY_STATE_GETTING, MESSAGE_EMPTY_STATE_NOT_EXIST} from '@/constants'
import {defineComponent, ref, computed} from 'vue'

const RegisterKeywordsModal = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    const rawFilter = ref<string|null>(null)
    const adGroups = computed(() => {
      return powerwordState.adGroups
    })

    const candidateKeywordsForViews = computed(() => {
      return powerwordState.candidateKeywordsForViews
    })

    const selectedAdGroup = computed(() => {
      return powerwordState.selectedAdGroup
    })

    const isActiveModal = computed(() => {
      return powerwordState.modal.registerKeywordsModal
    })

    const isLoadingKeywords = computed(() => {
      return powerwordState.loading.keywords
    })

    const closeRegisterKeywordsModal = () => {
      powerwordState.setRegisterKeywordsModal(false)
    }

    const candidateKeywordsEmptyState = () => {
      if (powerwordState.loading.keywords) {
        return MESSAGE_EMPTY_STATE_GETTING
      } else {
        return MESSAGE_EMPTY_STATE_NOT_EXIST
      }
    }

    const isRegisteredKeyword = (candidate: Keyword) => {
      return powerwordState.keywords.some((k) =>
          k.key === candidate.key)
    }

    // Multiselect
    const selectAdGroupOptions = computed(() => {
      return powerwordState.adGroupOptions
    })
    const selectedAdGroupValue = ref<AdGroup | null>(null)
    const onSelectAdGroup = (adGroup: AdGroup | null) => {
      powerwordState.setKeywordsLoading(true)
      powerwordState.setSelectedAdGroup(adGroup)
      if (adGroup) {
        keywordRepository.fetchCandidateKeywords({
          token: auth.token,
          adGroup,
        }).then((candidateKeywords) => {
          rawFilter.value = null
          powerwordState.setCandidateKeywords(candidateKeywords)
          powerwordState.reloadCandidateKeywordsForView([])
          powerwordState.setKeywordsLoading(false)
        })
      }
    }

    const onClickFilterKeywords = () => {
      const raw = rawFilter.value
      if (!raw) {
        powerwordState.reloadCandidateKeywordsForView([])
      } else {
        const keywordFilters = raw.split( '\n').map((filtered: any) => {
          return filtered.trim()
        }).filter((trimedFiltered: string) => trimedFiltered)
        powerwordState.reloadCandidateKeywordsForView(keywordFilters)
      }
    }

    const registerKeyword = (candidateKeyword: Keyword) => {
      const account = powerwordState.selectedAccount
      if (account) {
        keywordRepository.postKeyword({
          token: auth.token,
          user: auth.employeeNumber,
          candidateKeyword,
          account,
        }).then((ks) => {
          powerwordState.setKeywords(ks)
        })
      }
    }

    const statusFormat = (status: string | null) => {
      return Format.status(status)
    }

    const matchTypeFormat = (matchType: MATCH_TYPE) => {
      return Format.matchType(matchType)
    }

    // 表示系メソッド
    return {
      rawFilter,
      adGroups,
      candidateKeywordsForViews,
      selectedAdGroup,
      selectedAdGroupValue,
      selectAdGroupOptions,
      isActiveModal,
      isLoadingKeywords,
      closeRegisterKeywordsModal,
      candidateKeywordsEmptyState,
      isRegisteredKeyword,
      onSelectAdGroup,
      onClickFilterKeywords,
      registerKeyword,
      statusFormat,
      matchTypeFormat,
    }
  },
})
export default RegisterKeywordsModal
</script>
