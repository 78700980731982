import {
  AD_STRENGTH,
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  BulkEstimateRSAAdAsset,
  MEDIA_ID,
} from '@/stores/model/domain';
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor';

interface CheckResponse extends DataServiceResponse {
  is_valid: boolean
  error_request_invalid_account: RequestUploadRSAAdAsset[]
  error_request_invalid_adgroup: RequestUploadRSAAdAsset[]
  error_request_invalid_rsa_ad_asset: RequestUploadRSAAdAsset[]
  error_request_invalid_rsa_account_adgroup: RequestUploadRSAAdAsset[]
  error_request_duplicated_asset: RequestUploadRSAAdAsset[]
}

interface BulkEstimateResponses extends DataServiceResponse {
  results: BulkEstimateResponse[]
}

export interface RequestUploadRSAAdAsset {
  row_num: number
  media_id: MEDIA_ID
  account_id: string
  campaign_id: string
  adgroup_id: string
  ad_number: string
  asset_type: ASSET_TYPE
  pinned_field: ASSET_PINNED_FILED | null
  word: string
  is_editable: boolean
}

export interface BulkEstimateResponse extends RequestUploadRSAAdAsset {
  score: number | null
  ad_strength: AD_STRENGTH | null
}

class BulkUploadRSAAdAssetRepository {

  public async check(data: {
    token: string,
    user: string,
    requestUploadRSAAdAssets: RequestUploadRSAAdAsset[],
  }): Promise<RequestUploadRSAAdAsset[]> {
    const endpoint = `/rsa_ad_assets/bulk/check`
    return DataService.post<CheckResponse>({
      path: endpoint,
      param: {
        upload_rsa_ad_assets: data.requestUploadRSAAdAssets,
        user: data.user,
      },
      token: data.token,
    }).then((r) => {
      if (r.error_request_invalid_account.length +
        r.error_request_invalid_adgroup.length +
        r.error_request_invalid_rsa_ad_asset.length +
        r.error_request_invalid_rsa_account_adgroup.length +
        r.error_request_duplicated_asset.length <= 0) {
        return data.requestUploadRSAAdAssets
      } else {
        let errorInvalidAccountMessage = ''
        if (r.error_request_invalid_account.length > 0) {
          errorInvalidAccountMessage = 'アカウントが存在しません。<br/>' +
            r.error_request_invalid_account.map((errorRequestRSAAdAsset) => {
              return `${errorRequestRSAAdAsset.row_num}行目　` +
                `MediaId:${errorRequestRSAAdAsset.media_id}　` +
                `AccountId:${errorRequestRSAAdAsset.account_id}　`
            }).join('<br/>') + '<br/>'
        }
        let errorInvalidAdGroupMessage = ''
        if (r.error_request_invalid_adgroup.length > 0) {
          errorInvalidAdGroupMessage = 'アドグループが存在しないか、同期が完了していません。<br/>' +
            r.error_request_invalid_adgroup.map((errorRequestRSAAdAsset) => {
              return `${errorRequestRSAAdAsset.row_num}行目　` +
                `MediaId:${errorRequestRSAAdAsset.media_id}　` +
                `AccountId:${errorRequestRSAAdAsset.account_id}　` +
                `CampaignId:${errorRequestRSAAdAsset.campaign_id}　` +
                `AdGroupId:${errorRequestRSAAdAsset.adgroup_id}　`
            }).join('<br/>') + '<br/>'
        }
        let errorInvalidRSAAdAssetMessage = ''
        if (r.error_request_invalid_rsa_ad_asset.length > 0) {
          errorInvalidRSAAdAssetMessage = '一つのアドナンバーごとにTitleが1~15、Descriptionが1~4個必要です。<br/>' +
            r.error_request_invalid_rsa_ad_asset.map((errorRequestRSAAdAsset) => {
              // @ts-ignore
              const assetTypeKey = ASSET_TYPE[errorRequestRSAAdAsset.asset_type]
              return `${errorRequestRSAAdAsset.row_num}行目　` +
                `AdNumber:${errorRequestRSAAdAsset.ad_number}　` +
                `AssetType:${assetTypeKey}　` +
                `Word:${errorRequestRSAAdAsset.word}　`
            }).join('<br/>') + '<br/>'
        }
        let errorInvalidRSAAdGroupMessage = ''
        if (r.error_request_invalid_rsa_account_adgroup.length > 0) {
          errorInvalidRSAAdGroupMessage = '一つのアドナンバーごとに１つだけAccount、AdGroupを指定する必要があります。<br/>' +
            r.error_request_invalid_rsa_account_adgroup.map((errorRequestRSAAdAsset) => {
              return `${errorRequestRSAAdAsset.row_num}行目　` +
                `AdNumber:${errorRequestRSAAdAsset.ad_number}　` +
                `MediaId:${errorRequestRSAAdAsset.media_id}　` +
                `AccountId:${errorRequestRSAAdAsset.account_id}　` +
                `CmpId:${errorRequestRSAAdAsset.campaign_id}　` +
                `AdgId:${errorRequestRSAAdAsset.adgroup_id}　`
            }).join('<br/>') + '<br/>'
        }
        let errorDuplicatedAssetMessage = ''
        if (r.error_request_duplicated_asset.length > 0) {
          errorDuplicatedAssetMessage = 'アドナンバー内のアセットタイプ/アセット文言が重複しています。<br/>' +
            r.error_request_duplicated_asset.map((errorRequestRSAAdAsset) => {
              // @ts-ignore
              const assetTypeKey = ASSET_TYPE[errorRequestRSAAdAsset.asset_type]
              return `${errorRequestRSAAdAsset.row_num}行目　` +
                `AdNumber:${errorRequestRSAAdAsset.ad_number}　` +
                `AssetType:${assetTypeKey}　` +
                `Word:${errorRequestRSAAdAsset.word}　`
            }).join('<br/>')
        }
        return Promise.reject(errorInvalidAccountMessage +
          errorInvalidAdGroupMessage +
          errorInvalidRSAAdAssetMessage +
          errorInvalidRSAAdGroupMessage +
          errorDuplicatedAssetMessage)
      }
    })
  }

  public async estimate(data: {
    token: string,
    user: string,
    saveMode: boolean,
    requestUploadRSAAdAssets: RequestUploadRSAAdAsset[],
  }): Promise<BulkEstimateRSAAdAsset[]> {
    const endpoint = `/rsa_ad_assets/bulk/estimate`;
    return DataService.post<BulkEstimateResponses>({
      path: endpoint,
      param: {
        upload_rsa_ad_assets: data.requestUploadRSAAdAssets,
        save_mode: data.saveMode,
        user: data.user,
      },
      token: data.token,
    }).then((bers) => {
      return bers.results.map((ber) => {
        return {
          row_num: ber.row_num,
          media_id: ber.media_id,
          account_id: ber.account_id,
          campaign_id: ber.campaign_id,
          adgroup_id: ber.adgroup_id,
          ad_number: ber.ad_number,
          asset_type: ber.asset_type,
          pinned_field: ber.pinned_field,
          word: ber.word,
          is_editable: ber.is_editable,
          score: ber.score,
          ad_strength: ber.ad_strength,
        }
      })
    })
  }
}

export const bulkUploadRSAAdAssetRepository = new BulkUploadRSAAdAssetRepository()
