<template src='./Header.html'></template>

<script lang='ts'>
import { powerwordState } from '@/stores/state/powerword/powerword'
import { cmClientCompanyRepository } from '@/stores/repository/client_company'
import { accountRepository } from '@/stores/repository/account'
import { auth } from '@/stores/authorization-state'
import Multiselect from '@vueform/multiselect'
import { CmClientCompany } from '@/stores/model/domain'
import {defineComponent, computed, ref} from 'vue'

const HeaderComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    const fetchSetClientCompanies = () => {
      powerwordState.setIsLoadingClientCompanies(true)
      return cmClientCompanyRepository.fetchClientCompanies({ token: auth.token })
        .then((v: CmClientCompany[]) => {
          powerwordState.setIsLoadingClientCompanies(false)
          powerwordState.setClientCompanies(v)
      })
    }
    // initializer
    fetchSetClientCompanies()
    const isLoadingClientCompanies = computed(() => {
      return powerwordState.loading.clientCompanies
    })

    // Multiselect用
    const selectOptions = computed(() => {
      return powerwordState.clientCompanyOptions
    })
    const selectedClientCompanyValue = ref<CmClientCompany | null>(null)

    const onSelectClientCompany = (clientCompany: CmClientCompany) => {
      powerwordState.setSelectedClientCompany(clientCompany)
      powerwordState.setLoadingOn()
      // Account
      accountRepository.fetchAccounts({
        token: auth.token,
        cmClientCompanyId: clientCompany.cm_client_company_id,
      }).then((as) => {
        powerwordState.setAccounts(as)
      }).then(() => {
        powerwordState.setLoadingOff()
      })
    }

    return {
      isLoadingClientCompanies,
      onSelectClientCompany,
      selectOptions,
      selectedClientCompanyValue,
    }
  },
})
export default HeaderComponent
</script>


