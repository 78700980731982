const REGEX_SPECIAL_CHARS = [
  ".",
  "?",
  "+",
  "*",
  "|",
  "[",
  "]",
  "(",
  ")",
  "^",
  "$",
]

// 正規表現のパターンを取得。strはNGWord、BannedAssetを想定
export const generateRegexPattern = (str: string): RegExp => {
  let pattern = str
  // NGWordに正規表現に引っかかる文言が入ってきた場合にCheck前に変換する
  REGEX_SPECIAL_CHARS.forEach((rsc) => {
    pattern = pattern.replace(rsc, `\\${rsc}`)
  })
  // 大小文字を区別しない
  return new RegExp(`${pattern}`, 'ig')
}
