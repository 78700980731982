<template>
</template>

<script lang="ts">
import {defineComponent, PropType, toRefs} from 'vue'
import router from '@/router'
import { auth } from '@/stores/authorization-state'

interface SSOQuery {
  code: string
}

const SSOComponent = defineComponent({
  components: {
  },
  props: {
    query: {
      type: Object as PropType<SSOQuery>,
      required: true,
    },
    path_1st: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const initialize = () => {
      const { query, path_1st } = toRefs(props)
      if (query.value.code !== '') {
        const path = '/' + path_1st.value
        auth.requestToken(query.value.code).then(() => {
          router.push({ path })
        }).catch((e) => {
          router.push('/unauthorized')
        })
      }
    }
    initialize()
  },
})
export default SSOComponent
</script>
