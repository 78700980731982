<template src="./BulkRSAAd.html"></template>
<style lang="scss" src="@/components/pages/PWAssetCreate/BulkRSAAdComponent/BulkRSAAd.scss"></style>
<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import {Account, Campaign} from '@/stores/model/domain'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import Multiselect from '@vueform/multiselect'
import {campaignRepository} from '@/stores/repository/campaign'
import {bulkUploadRSAAdRepository} from '@/stores/repository/bulk_upload_rsa_ad'
import {CSVDownloader} from '@/csv-downloader';
import {CSVDownloadContents} from '@/csv-download-contents';

const BulkRSAAdComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    const MAX_ADGROUP_IDS = 10000
    const errorMessage = ref<string | null>(null)
    const campaigns = ref<Campaign[]>([])
    const inputAdgroupIds = ref<string>('')

    // Client
    const selectedClient = computed(() => {
      return powerwordState.selectedClientCompany
    })

    // Account
    const selectedAccount = computed(() => {
      return powerwordState.selectedAccount
    })

    const accounts = computed(() => {
      return powerwordState.accounts
    })

    // Multiselect用
    const selectAccountOptions = computed(() => {
      return powerwordState.accountOptions
    })
    const selectedAccountValue = ref<Account | null>(null)
    const onSelectAccount = (account: Account) => {
      powerwordState.setSelectedAccount(account)
      powerwordState.setLoadingOn()
      campaigns.value = []
      campaignRepository.fetchCampaigns({
        token: auth.token,
        account,
      }).then((cs) => {
        campaigns.value = cs
        powerwordState.setLoadingOff()
      })
    }

    // Download
    const canDownload = computed<boolean>(() => {
      const account = powerwordState.selectedClientCompany
      const adgIds = getIds()
      return !!account && adgIds.length > 0
    })

    const onClickDownload = () => {
      const account = powerwordState.selectedAccount
      const adgIds = getIds()
      if (!account || adgIds.length <= 0) { return }
      if (adgIds.length > MAX_ADGROUP_IDS) {
        errorMessage.value = `AdGroupIdsの数が上限（${MAX_ADGROUP_IDS}）を超えています`
        return
      }
      errorMessage.value = ''
      powerwordState.setLoadingOn()
      bulkUploadRSAAdRepository.download({
        token: auth.token,
        user: auth.employeeNumber,
        account,
        adgIds,
      }).then((bras) => {
        powerwordState.setLoadingOff()
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateBulkRSAAdContent(bras)
        downloader.download(content, 'BulkRSAAd')
      }).catch((e) => {
        errorMessage.value = e
        powerwordState.setLoadingOff()
      })
    }

    const getIds = () => {
      const ids = inputAdgroupIds.value.split(/[,\n]/).flatMap((id) => {
        const trimed = id.trim()
        if (trimed.length <= 0) {
          return []
        }
        return trimed
      }) as string[]
      return ids
    }

    return {
      errorMessage,
      selectedClient,
      selectedAccount,
      accounts,
      selectAccountOptions,
      selectedAccountValue,
      campaigns,
      onSelectAccount,
      inputAdgroupIds,
      canDownload,
      onClickDownload,
    }
  },
})

export default BulkRSAAdComponent
</script>
