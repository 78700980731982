import {
  Account,
  AdGroup,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor';

interface CampaignAdGroupResponses extends DataServiceResponse {
  adgroups: CampaignAdGroupResponse[];
}

interface CampaignAdGroupResponse extends DataServiceResponse {
  ss_id: number;
  media_id: number;
  account_id: string;
  campaign_id: string;
  campaign_name: string;
  campaign_status: boolean;
  adgroup_id: string;
  adgroup_name: string;
  adgroup_status: boolean;
}

class CampaignAdGroupRepository {
  public async fetchCampaignAdGroups(data: {
      token: string,
      account: Account,
    }): Promise<AdGroup[]> {
    const endpoint = `/campaign/adgroup/${data.account.ss_id}/${data.account.media_id}`;
    return DataService.getCache<CampaignAdGroupResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((as) => {
      return as.adgroups.map((a) => {
        return {
          ss_id: a.ss_id,
          media_id: a.media_id,
          account_id: a.account_id,
          campaign_id: a.campaign_id,
          campaign_name: a.campaign_name,
          campaign_status: a.campaign_status,
          adgroup_id: a.adgroup_id,
          adgroup_name: a.adgroup_name,
          adgroup_status: a.adgroup_status,
          track_id: a.campaign_id + ':' + a.adgroup_id + ':' + a.campaign_name + ':' + a.adgroup_name,
          label: a.adgroup_id + ' - ' + a.adgroup_name,
        };
      });
    });
  }
}

export const campaignAdgroupRepository = new CampaignAdGroupRepository()
